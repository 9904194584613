//transalate
import { useTranslation } from 'react-i18next';

interface Props {
  name:string,
  description:string,
  type:string
}

const ReportCardAsset = ({name,description,type}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='ReportCardAsset'>
        <span className='ReportCardAsset-title' >{name}</span>
        
        <div className='ReportHeader-titleText'>
            <span>{t('basico.descripcion')}:</span>
            <span>{description}</span>
        </div>

        <div className='ReportHeader-titleText'>
            <span>{t('basico.tipo')}:</span>
            <span>{type}</span>
        </div>
    </div>
  )
}

export default ReportCardAsset