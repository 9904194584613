import { generateTempFormat } from '../Functions/temp'

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
  name:string,
  description:string,
  details:any
}

const ReportTaskDetail = ({name, description, details}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='ReportTaskDetail ReportLayot'>

      <div className='ReportSub-title'>
        <span>{t('activo.detalleTarea')}</span>
      </div>  

      <div className='ReportTaskDetail-list'>


        <div className='ReportTaskDetail-sublist'>
          
          <div className='ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.nombreTarea')}:</span>
                <span>{name}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.descripcionTarea')}:</span>
                <span>{description}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.tempBaja')}:</span>
                <span>{generateTempFormat(details?.alarm_low_temp)}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.tempAlta')}:</span>
                <span>{generateTempFormat(details?.alarm_high_temp)}</span>
            </div>
          </div>

        </div>

        <div className='ReportTaskDetail-sublist'>
          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.duracionBaja')}:</span>
                <span>{details?.low_duration_minutes} Min</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.duracionAlta')}:</span>
                <span>{details?.high_duration_minutes} Min</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.intervalo')}:</span>
                <span>{details?.interval_minutes} Min</span>
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default ReportTaskDetail