import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';

import { PHONECODE } from '../../statics/PhoneCode';


interface Props{
    onFinishMaster: (values: any) => void

  }

const AddNotificationsFormMovil = ({onFinishMaster}:Props) => {

    const { t } = useTranslation();

    const formItemLayoutWithOutLabel = {

      };

      const onFinish = (values: any) => {
        var newValues:any[] = []
        values.users.forEach((x:any)=>{

            newValues.push(x.prefijo+x.numero)
        })
       onFinishMaster({phone:newValues})
        // onFinishMaster(values.dragger[0].originFileObj as File)
    };

    function compareFn(a:any, b:any) {
        return Number(a.dial_code) - Number(b.dial_code)
      }

      const prefixSelector= (index:number) => (
        <Form.Item name={"prefix"+ (index+1)} key={index} noStyle rules={[{ required: true, message: t('basico.errorPrefijo')+index }]}>
          
          <Select style={{ width: 120 }}>
            {PHONECODE.sort(compareFn).map((x)=>{
              return <Select.Option value={"+"+x.dial_code}>+{x.dial_code} - {x.code}</Select.Option>
            })}
            
          </Select>
        </Form.Item>
      );

  return (
    <div>
          <Form
    name="dynamic_form_nest_item"
    onFinish={onFinish}
    autoComplete="off"
  >
    <Form.List name="users">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <Form.Item
                {...restField}
                label={t('basico.telefono')}
                name={[name, 'prefijo']}
                rules={[{ required: true, message: 'Missing first name' }]}
              >
                <Select style={{ width: 120 }}>
            {PHONECODE.sort(compareFn).map((x)=>{
              return <Select.Option value={"+"+x.dial_code}>+{x.dial_code} - {x.code}</Select.Option>
            })}
            
          </Select>
              </Form.Item>
              <Form.Item
                      {...restField}
                      
                      validateTrigger={['onChange', 'onBlur']}
                      style={{ width: "100%" }}
                      name={[name, 'numero']}
                      rules={[
                        { required: true, message: t('basico.errorTelefono') },
                        {
                          type: "number",
                          message: t('basico.formato'),
                          transform(value) {
                            return Number(value);
                          }
                        },
                        { max: 12, message: t('basico.max12') }
                      ]}
                    >
                      <Input placeholder='000-000-000-' style={{ width: '100%' }} />
                    </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('basico.agregar')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
            <Button type="primary" htmlType="submit">
                {t('basico.enviar')}
            </Button>
        </div>
  </Form>
    </div>
  )
}

export default AddNotificationsFormMovil