//transalate
import { useTranslation} from 'react-i18next';

const ReportNotes = () => {
  const { t } = useTranslation();
  return (
    <div className='ReportNotes ReportLayot'>
        <div className='ReportNotes-section ReportNotes-left'>
            <span>{t('basico.observaciones')}</span>
            <div></div>

        </div>

        <div className='ReportNotes-section ReportNotes-right'>
            <span>{t('basico.firmaRevisor')}</span>

            <div></div>
        </div>
    </div>
  )
}

export default ReportNotes