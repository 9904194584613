import { generateTempFormat } from '../Functions/temp'

interface Props{
    alarm:any
}

const ReportListAlarms = ({alarm}:Props) => {
  return (
    <div className='ReportTaskDetail-AlarmList'>

          <div className='AlarmDetail-Component'>
            <span>Fecha y Hora</span>
          </div>

          <div className='AlarmDetail-Component'>
            <span>Temperatura</span>
          </div>

          <div className='AlarmDetail-Component'>
            <span>Tipo de Alarma</span>
          </div>

          <div className='AlarmDetail-Component'>
            <span> </span>
          </div>

          <div className='AlarmDetail-Component'>
            <div><span>{alarm?.occurred}</span></div>
          </div>

          <div className='AlarmDetail-Component'>
            <div><span>{generateTempFormat(alarm?.temperature)}</span></div>
          </div>

          <div className='AlarmDetail-Component'>
            <div><span>{alarm?.alarm_type}</span></div>
          </div>

          <div className='AlarmDetail-Component'>
            <div><span> -  </span></div>
          </div>

       
    </div>
  )
}

export default ReportListAlarms