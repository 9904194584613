import { Modal } from 'antd'
import React from 'react'
import AlertAsset from './AlertAsset'
import { useTranslation} from 'react-i18next';

interface Props{
    id:number
    open:boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalAlertAsset = ({id, open, setOpen}:Props) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('basico.alarmas')} open={open} footer={null} onCancel={()=>{setOpen(false)}} >
        <AlertAsset assetId={id} />
    </Modal>
  )
}

export default ModalAlertAsset