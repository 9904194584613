import { Button, Form, Input, Select } from 'antd';
import { FieldType } from './UpdateApikey';

//transalate
import { useTranslation} from 'react-i18next';

interface Props{
    onFinishMaster: (values: any) => void
    // defaultValues?:FieldType
    initialValues?:FieldType
}

const UpdateApiKeyForm = ({onFinishMaster, initialValues}:Props) => {
    const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };


  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    style={{padding:"20px 0 0 0"}}
    initialValues={initialValues}
    // initialValues={defaultValues}
    >
        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
        >
        <Input />
        </Form.Item> 

        <Form.Item<FieldType>
            name={"tipo"}
            label={t('basico.tipo')}
            rules={[
            {required: true,
            message: "Seleccione un tipo",},
            ]} style={{width:"100%"}}>

            <Select style={{width:"100%"}}>
                <Select.Option value={"Servicio"}>{t('basico.servicio')}</Select.Option>
                <Select.Option value={"Única"}>{t('basico.unica')}</Select.Option>

            </Select>
        </Form.Item>

        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.enviar')}
        </Button>
      </div> 

    </Form>
  )
}

export default UpdateApiKeyForm