import { Button, Card, Form, Input} from 'antd'
import  { useContext, useEffect, useState } from 'react'

import "./Profile.css"
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { getProfile, postProfile } from '../../Api/Users';
import Spiner from '../Spiner/Spiner';

//transalate
import { useTranslation} from 'react-i18next';

type FieldType = {
    nombres: string;
    apellidos: string;
    usuario:string
    correo:string
    contrasena:string;
  };

const Profile = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [initialValues, setInitialValues] = useState<FieldType>()

    const { t } = useTranslation();
    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);

    const onFinish = async (values: any) => {
        try{
            setLoading(true)
            let res = await postProfile({
                nombres: values.nombres,
                apellidos: values.apellidos,
                contrasena: values.contrasena || "",
              })


            setLoading(false)

        }
        catch(err){
          setLoading(false)
          sendError({type: "ERROR", data:{message:t('basico.errorDatos')}})
        }




        
      };
      
    const onFinishFailed =async (errorInfo: any) => {

      };



      const getData = async  () =>{
        try{
            setLoading(true)
            let res = await getProfile()

            if(res){
                setInitialValues({
                    nombres:res.nombres,
                    apellidos:res.apellidos,
                    usuario:res.usuario,
                    contrasena:"", 
                    correo:res.correo
                })
            }
            setLoading(false)
        }
        catch(err){
          setLoading(false)
          sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})
        }
      }

      useEffect(() => {

        getData()
      }, []);

  return (
    <Card title={t('basico.gestionPerfil')} style={{height:"calc(100vh - 2rem)", borderRadius:"0 8px 8px 0"}} bodyStyle={{padding:0, height:"90%", boxSizing:"border-box"}} >
        <Spiner loading={loading} >
        
        <Form name="form_item_path" 
        layout="vertical" 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{height:"100%"}}
        initialValues={initialValues}
        >
        <div className='Profile-Form'>

            <div className='CreateClient-Form Profile-Form-div'>
            
            <Form.Item<FieldType> 
        label={t('basico.nombre')}
        name="nombres"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
        >
        <Input />
        </Form.Item>    

        <Form.Item<FieldType>
        label={t('basico.apellidos')}
        name="apellidos"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorApellidos') }, {max:30, message:t('basico.max30')}]}
        >
        <Input />
        </Form.Item> 

        <Form.Item<FieldType>
        label={t('basico.correo')}
        name="correo"
        
        style={{width:"100%"}}
        rules={[{ required: true, message: '' }]}
        >
            <Input disabled />
        </Form.Item> 

        <Form.Item<FieldType>
        label={t('basico.usuario')}
        name="usuario"
        
        style={{width:"100%"}}
        rules={[{ required: true, message: '' }]}
        >
            <Input disabled />
        </Form.Item> 

        <Form.Item<FieldType>
        label={t('basico.clave')}
        name="contrasena"
        style={{width:"100%"}}
        >
        <Input placeholder={t('usuario.placeHolderClave')} />
        </Form.Item>   


        <div style={{width:"100%",display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
            <Button type="primary" htmlType="submit">
             {t('basico.guardar')}
            </Button>
        </div> 

            </div>
 
        </div>
        </Form>
        </Spiner>
    </Card>
  )
}

export default Profile