import { Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useContext, useState } from 'react'
import { User } from '../../Models/User';
import { MenuItem } from '../../Models/MenuItem';
import { DeleteOutlined, EditOutlined, TagOutlined } from '@ant-design/icons';

//transalate
import { useTranslation} from 'react-i18next';
import ModalButton from '../ModalButton/ModalButton';
import { BsFillUnlockFill, BsLockFill, BsPencilSquare } from 'react-icons/bs';
import ChangeRol from '../ChangeRol/ChangeRol';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

import { patchBlockUser } from '../../Api/Organization';
import { patchBlockUser as patchBlockUserReseller } from '../../Api/Reseller';
import { patchBlockUser as patchBlockUserSubsidiary } from '../../Api/Subsidiary';
import { patchBlockUser as patchBlockUserSupplier } from '../../Api/Supplier';

interface Props{
  users:User[], 
  refresh:() => Promise<void>
}

const TableUsers = ({users, refresh}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true)
    const {sendError } = useContext(PromiseSesionContext);
    const { sesion } = useContext(PromiseSesionContext);

    const menuItems:MenuItem[] = [
        {
          label: <span>Eliminar</span>,
          key: '0',
          title: 'Eliminar Cliente',
          icon: <DeleteOutlined />,
          component: <div>Eliminar</div>,
          type: 'CONFIRM',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        },
        {
          label: <span>Editar</span>,
          title: 'Detalle Cliente',
          key: '1',
          icon: <EditOutlined />,
          component: <div>Editar</div>,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        },
      ]

      const lockUser =async  (idUser:number)=>{
        try{
          setLoading(true)
          let res:any
          switch (sesion.context.rol) {
            case "Proveedor":
              res = await patchBlockUserSupplier(idUser)
              break;
            case "Reseller":
                res = await patchBlockUserReseller(idUser)
              break;
            case "Organizacion":
                 res = await await patchBlockUser(idUser)
              break;
            case "Sucursal":
                res = await patchBlockUserSubsidiary(idUser)
              break;
            default:
              break;
          }
          refresh()
          setLoading(false)
        }
        catch(err){
          sendError({type: "ERROR", data:{message:t('basico.errorActualizarDatos')}})
          setLoading(false)
        }
      }

    const columns: ColumnsType<User> = [
        {
            title: t('basico.nombre'),
            dataIndex: 'nombres',
            key: 'nombres',
            // responsive:["sm"]
        },    
        {
            title: t('basico.apellidos'),
            dataIndex: 'apellidos',
            key: 'apellidos',
            // responsive:["sm"]

        },
        {
          title: t('basico.usuario'),
          dataIndex: 'usuario',
          key: 'usuario',
        },
    
        {
          title: t('basico.tipo'),
          key: 'tipoEntidad',
          dataIndex: 'tipoEntidad',
          
          render: (_, { rol, id }) => {
            let menuItem:MenuItem =   {
              label: <span>Sensores</span>,
              title: t('usuario.changeRol'),
              key: '1',
              icon: <TagOutlined />,
              component: <ChangeRol userID={id} refresh={refresh} rol_id={rol.id}/>,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {},
        
            }

            return <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
              <Tag color={"geekblue"} key={id}>
                  {rol.nombre.toUpperCase()}
              </Tag>
              <ModalButton menuItem={menuItem} text={""}  icon={<BsPencilSquare /> }></ModalButton>
            </div>}
          ,
        },

        {
          title: t('basico.acciones'),
          key: 'acciones',
          dataIndex: 'tipoEntidad',
          
          render: (_, { blocked, id }) => {
            return <>{!(sesion.context.user.rol==="admin") ?<></> : !blocked ? <BsFillUnlockFill style={{cursor:"pointer"}} onClick={()=>{lockUser(id)}}  /> : <BsLockFill style={{cursor:"pointer"}} onClick={()=>{lockUser(id)}}  />}</>}
          ,
        },

      ];

  return (
      
      <Table  scroll={{x:100}} pagination={false} columns={columns} dataSource={users} />
  )
}

export default TableUsers