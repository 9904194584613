//transalate
import { useTranslation } from 'react-i18next';

//styles
import "./ReportStyles.css"

import ReportHeader from './ReportHeader'
import ReportFooter from './ReportFooter'
import ReportCardAsset from './ReportCardAsset'
import ReportCardSensor from './ReportCardSensor'
import ReportTaskDetail from './ReportTaskDetail'
import ReportTempDetail from './ReportTempDetail'
import ReportAlarmDetail from './ReportAlarmDetail'
import ReportNotes from './ReportNotes'
import SubsidiaryGraph from '../Components/SubsidiaryGraph/SubsidiaryGraph'
import NoData from '../Components/NoData/NoData'

interface Props {
  data:any
  pages:number
}

const ReportDetail = ({data, pages}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='ReportDetail'>
      <ReportHeader organization={data?.subsidiary?.organizacion?.nombre || []} subsidiary={data?.subsidiary?.nombre || []} />

      <div className='ReportLayot Report-divided-layout'>
        <ReportCardAsset description={data?.activo?.descripcion || ""} name={data?.activo?.nombre || ""} type={data?.activo?.tipo || ""} />

        <ReportCardSensor batery={data?.sensor?.battery_level || 0} model={data?.sensor?.model || ""} serial={data?.sensor?.numero_de_serie || ""} />
      </div>

      <div style={{ width:"100%"}}>


      <ReportTaskDetail description={data?.descripcion || ""} name={data?.nombre || ""} details={data?.taskDetails} />


      <ReportTempDetail alarmCount={data?.alarmsDetails?.length} data={data?.temperatureDetails} />


      <ReportAlarmDetail alarms={data?.alarmsDetails} />

      <div style={{width:"100%", height:"300px"}}>
        <NoData data={data?.temperatureDetails} text={t('sensor.sinDatos')} >
          <SubsidiaryGraph maxRef={data?.taskDetails?.alarm_high_temp} minRef={data?.taskDetails?.alarm_low_temp} enterData={[{taskName: "Nevera para prueba v", sensorData:data.temperatureDetails, sensorSerial:data?.sensor?.serial || "Sensor", sensorName:""}]} />
        </NoData>

      </div>

      <ReportNotes />




      </div>
      <ReportFooter actualPage={1} pages={pages} email={data?.reportGenerateBy} />


    </div>
  )
}

export default ReportDetail