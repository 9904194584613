import React, { useContext } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import Spiner from '../Spiner/Spiner'
import AddNotificationForm from './AddNotificationForm'
import { postNotifications } from '../../Api/Organization'
import { useTranslation } from 'react-i18next'

interface Props{

    idSubsidiary:number
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>

  }


const AddNotificationSimple = ({setOpen, idSubsidiary}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [messageResponse, setMessageResponse] = React.useState<string>("");

    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);

    const onFinish = async (values: any) => {
        try{
            setLoading(true)

            const res = await postNotifications(idSubsidiary, values)
            // const res = await postSensorsFileReport(formData)
            sendError({type: "ERROR", data:{message: t('basico.solicitudEnviada') , type:"success" }})
  
            setLoading(false)
            setOpen!(false)
        }
        catch(e:any){

            sendError({type: "ERROR", data:{message: e?.response?.data?.message  || t('basico.errorTransaccion')}})
            setLoading(false)

        }
      };

  return (
    <div >
      <Spiner loading={loading}>
      <AddNotificationForm onFinishMaster={onFinish} />
      </Spiner>
    </div>
  )
}

export default AddNotificationSimple