import React, { useContext, useEffect } from 'react'
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess'
import CreateAsset, { FieldType } from './CreateAsset'
import { getEnergyClassification, postAsset } from '../../Api/Subsidiary'
import Spiner from '../Spiner/Spiner'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { postAddAssetToSubsidiary } from '../../Api/Organization'
import { useParams } from 'react-router-dom'
import CreateAssetEnergy from './CreateAssetEnergy'
import { energyClassification } from '../../Models/EnergyClassification'

//transalate
import { useTranslation} from 'react-i18next';

interface Props{
    step?:number
    setStep?: React.Dispatch<React.SetStateAction<number>>
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>

  }

const CreateAssetComponent = ({step, setStep, setOpen}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [asset, setAsset] = React.useState<FieldType>({});
    const [categories, setCategories] = React.useState<energyClassification[]>([]);

    const [messageResponse, setMessageResponse] = React.useState<string>("");
    const {sendError, sesion} = useContext(PromiseSesionContext);
    const params = useParams();

    const getCategory = async () =>{
      try{
        // setLoading!(true)
        setLoading(true)
        
        const res =  await getEnergyClassification()
        
        
        setCategories(res)
        setLoading(false)


      }
      catch(err){
        setLoading!(false)
        sendError({type: "ERROR", data:{message:t('activo.errorCategorias')}})

      }

    }


    const onFinishClient = async (values: any) => {
      try{
        // setLoading!(true)
        setAsset(values)
        setStep!(1)
        return 
        const res = sesion.context.rol==="Sucursal" ? await postAsset(values) : await postAddAssetToSubsidiary(params.idSucursal || 0, values)


        if(res) setLoading!(false)

      }
      catch(err){
        setLoading!(false)
        sendError({type: "ERROR", data:{message:t('activo.errorCrear')}})

      }
    };

    
    useEffect(() => {
      getCategory()

  }, []);

  const onFinishClientEnergy = async (values: any) => {
    try{
      // setLoading!(true)
      // setAsset(values)

      let objectAsset ={
        "nombre": asset.nombre,
        "descripcion": asset.descripcion,
        "modo": asset.modo,
        "consumo_energetico_id": values.category ,
        "tipo":asset.tipo,
        temp_oper_alta: values.range[1] || 0,
        temp_oper_baja: values.range[0] || 0
      }
      
      
      const res = sesion.context.rol==="Sucursal" ? await postAsset(objectAsset) : await postAddAssetToSubsidiary(params.idSucursal || 0, objectAsset)

      setStep!(2)
      if(res) setLoading!(false)

    }
    catch(err){
      setLoading!(false)
      sendError({type: "ERROR", data:{message:t('activo.errorCrear')}})

    }
  };

  
  useEffect(() => {
    getCategory()

}, []);


  const back = () => {
    setStep!(step===0 ? step : step!-1)
  };

  return (
    <div>
      <Spiner loading={loading}>

        {step===0 ? <CreateAsset initialValues={asset} onFinishMaster={onFinishClient} /> : step===1 ? <CreateAssetEnergy categories={categories} onFinishMaster={onFinishClientEnergy} /> : <FinishedSuccess titulo='Activo creado exitasamente' loading={loading} onFunction={()=>{setOpen!(false)}} />}
      </Spiner>
    </div>
  )
}

export default CreateAssetComponent