import { useContext, useEffect, useState } from 'react'
import { Card, Pagination } from 'antd'
import TableRequest from '../../Components/TableRequest/TableRequest'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { getRequests } from '../../Api/Supplier';
import { Request  as RequestModel } from '../../Models/Request';
import Spiner from '../../Components/Spiner/Spiner';

//transalate
import { useTranslation } from 'react-i18next';

import './Request.css'

const Requests = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState<RequestModel[]>([]);


  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 8

  const { sendError } = useContext(PromiseSesionContext);

  const getRequest = async () => {
    try{
      setLoading(true)
      let res = await getRequests()

      setRequests(res)
      setLoading(false)

    }
    catch(err){

    setLoading(false)
    sendError({type: "ERROR", data:{message:t('solicitud.errorSolicitudes')}})

  }
  }

  useEffect(() => {
    getRequest()
  }, []);

  
  return (
    <div>
        <Card  title={t('solicitud.titulo')} style={{height:"calc(100vh - 2rem)"}} bodyStyle={{padding:0, height:"82%"}}  >
          <div className='Layout-Card-Paginate'>
            <Spiner loading={loading}>
              <div className='request-table'>
                <TableRequest getData={()=>{getRequest()}} setLoading={setLoading} requests={requests?.slice((page - 1) * sizePages, page * sizePages)} />
              </div>

            <div className='centerPaginate'>
              {requests.length > sizePages &&<Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={requests.length} pageSize={sizePages} showSizeChanger={false} />}
            </div>
            </Spiner>
          </div>
        </Card>      
    </div>
  )
}

export default Requests