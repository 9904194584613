import { useContext, useEffect, useState } from 'react'
import { MenuItem } from '../../Models/MenuItem'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import CreateResellerComponent from '../../Components/CreateReseller/CreateResellerComponent'
import { Card, Pagination } from 'antd'

//transalate
import { useTranslation } from 'react-i18next';

//api
import { getResellers } from '../../Api/Supplier'
import { Reseller } from '../../Models/Reseller'
import { PromiseSesionContext } from '../../Machines/SesionMachine'

//style
import './Reseller.css'
import ElementCardReseller from '../../Components/ElementCard/ElementCardReseller'
import Spiner from '../../Components/Spiner/Spiner'
import ResellerFilters from '../../Components/ResellerFilters/ResellerFilters'
import NoData from '../../Components/NoData/NoData'



const Resellers = () => {
    const [loading, setLoading] = useState(false);
    const [resellers, setResellers] = useState<Reseller[]>([]);
    const [resellersFilters, setResellersFilters] = useState<Reseller[]>([]);
    const { sendError } = useContext(PromiseSesionContext);

    const [filters, setFilters] = useState<{nombre:string}>({nombre:""}); //filters

    const { t } = useTranslation();

    //paginate
    const [page, setPage] = useState<number>(1)
    const sizePages = 12

    const items=[
        {
          title: t('revendedor.crearTab1'),
        },
        {
          title: t('revendedor.crearTab2'),
        },
        {
          title: t('revendedor.crearTab3'),
        },
      ]
    
      const menuItem:MenuItem ={
        label: <span>Crear</span>,
        key: '0',
        title: t('revendedor.crear'),
        icon: <></>,
        component: <CreateResellerComponent />,
        type: 'MULTICONTENT',
        items: items,
        size: 'small',
        onConfirm: () => {getData()},
      }

  const getData = async () => {
    try{
    setLoading(true)
    const res = await getResellers()

    setResellers(res)
    setResellersFilters(res)
    if(res) setLoading(false)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('revendedor.errorCarga')}})
      setLoading(false)
    }

  }

  const filterByName = ()=>{
    setResellersFilters(resellers.filter((r:Reseller) => r.nombre.toUpperCase().includes(filters.nombre.toUpperCase())))

  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(filters.nombre!=""){
      filterByName()
    }
    else{
      setResellersFilters(resellers)
    }
  }, [filters]);

  return (
    <div>
      <Card title={t('revendedor.titulo')} className='reseller-card'  bodyStyle={{padding:"0", height:"82%"}} extra={<FilterSimple menuItem={menuItem}  />}>
          <Spiner loading={loading}>
          <ResellerFilters filters={filters} setFilters={setFilters} />

            <NoData data={resellersFilters} text={t('revendedor.noRevendedores')}>
              <div className='Layout-Card-Paginate'>

                <div className='ResellerPage-list' style={{padding:"12px"}}>        
                    {resellersFilters?.slice((page - 1) * sizePages, page * sizePages).map((reseller, index) => {
                      return (
                        <ElementCardReseller elementClass='Card-Full-Width' reseller={reseller} />
                      )
                    })}
                </div>

                <div className='centerPaginate'>
                {resellersFilters?.length >sizePages &&
                  <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={resellersFilters.length} pageSize={sizePages} showSizeChanger={false} />
                  }
                </div>
              </div>
            </NoData> 
          </Spiner>
      </Card>
    </div>
  )
}

export default Resellers