import { Input, Select } from 'antd'
import React from 'react'

//transalate
import { useTranslation} from 'react-i18next';

interface Props {
    filters: {status:string, nombre:string},
    setFilters: React.Dispatch<React.SetStateAction<{status:string, nombre:string}>>,
}

const UsersFilters = ({filters, setFilters}:Props) => {
    const { t } = useTranslation();
    const handleChangeNombre = (value: string) => {
        setFilters({...filters, nombre: value})
    };

    const handleChange = (value: string) => {
        setFilters({...filters, status: value})
      };

    const statusOptions = [
        {label: t('basico.todos'), value: ""},
        {label: t('basico.administrador'), value: "admin"},
        {label: t('basico.usuario'), value: "user"},
    ]

  return (
    <div style={{padding:"12px", borderBottom:"1px solid #f0f0f0"}}>
        <Input className='double-Filter' placeholder={t('basico.placeHolderNombreBuscar')} value={filters.nombre} style={{ marginRight:"15px" }} onChange={(event)=>{handleChangeNombre(event.target.value)}} />

        <Select
        allowClear
        
        className='double-Filter'
        defaultValue={filters.status}
        value={filters.status}
        onChange={handleChange}
        options={statusOptions}
        />
    
    </div>
  )
}

export default UsersFilters