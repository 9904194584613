import React from 'react'
import { Select } from 'antd'
import Input from 'antd/es/input/Input';

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
    filters: {status:string, nombre:string},
    setFilters: React.Dispatch<React.SetStateAction<{status:string, nombre:string}>>,
    notShowAsign ?: boolean
}

const SensorFilters = ({filters, setFilters, notShowAsign}:Props) => {
    const { t } = useTranslation();
    const handleChange = (value: string) => {
        setFilters({...filters, status: value})
      };

    const changeText = (value:string) => {
      setFilters({...filters, nombre: value})

    }

    const statusOptions = [
        {label: t('basico.todos'), value: ""},
        {label: t('basico.asignado'), value: "Asignados"},
        {label: t('basico.sinAsignar'), value: "Sin asignar"},
    ]

  return (
    <div style={{padding:"12px", borderBottom:"#f0f0f0 1px solid"}}>
      <Input className='double-Filter' onChange={(e)=>{changeText(e.target.value)}} placeholder={t('sensor.buscarPorSerial')} style={{marginRight:"15px" }} />
      {!notShowAsign &&<Select
      className='double-Filter'
      allowClear
      placeholder="Filtrar por status"
      defaultValue={filters.status}
      onChange={handleChange}
      options={statusOptions}/>}
    </div>
  )
}

export default SensorFilters