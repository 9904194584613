import React, { useContext } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import UpdateApiKeyForm from './UpdateApiKeyForm';
import { patchUpdateApikey } from '../../Api/Reseller';

//transalate
import { useTranslation} from 'react-i18next';

export type FieldType = {
    nombre?: string
    tipo?:string
  };

interface Props{
    nombre:string
    tipo:string
    token:string
    closeModal?:()=>void
}

const UpdateApikey = ({nombre, tipo, token, closeModal}:Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0);
    const [messageResponse, setMessageResponse] = React.useState<string>("");
  
    const { t } = useTranslation();
    const {sendError, sesion} = useContext(PromiseSesionContext);

    const onFinish= async (values:any)=>{
        try{
            setLoading(true)
            const res = await patchUpdateApikey(token,values) 
            setMessageResponse(t('apikey.apikeyActualizada'))
            setStep(1)
            setLoading(false)
      
          }
          catch(err){
            sendError({type: "ERROR", data:{message:t('apikey.errorActualizar')}})
            setLoading(false)
          }
      }
    
  return (
    <div>
        <Spiner loading={loading}>
            {step === 0 ?
            <UpdateApiKeyForm onFinishMaster={onFinish} initialValues={{nombre:nombre, tipo:tipo}}/>
            :
            <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{closeModal!()}} />
            }
        </Spiner>
    </div>
  )
}

export default UpdateApikey