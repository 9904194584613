import { Route } from '../../Models/Route';
import { useNavigate } from 'react-router-dom';

interface Props {
    route:Route
}

const NavbarMovilItem = ({route}:Props) => {
    const navigate = useNavigate();

    const changePage = (page:string)=>{
        window.scrollTo(0, 0)
        navigate(page);
    }

  return (
    <div className='NavBar-Link-movil'>
        <div className={'NavBar-LinkIcon-movil' + ((window.location.pathname== route.path) ? " NavBar-LinkIconSelected-movil" : "")} onClick={()=>{changePage(route.path);}}>
            {route.icon}
        </div>
        {(window.location.pathname== route.path) &&<div className='NavBar-Selected-movil'></div>}
    </div>
  )
}

export default NavbarMovilItem