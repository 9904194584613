import React, { useContext, useEffect } from 'react'
import Spiner from '../Spiner/Spiner';
import CreateSite from './CreateSite';
import CreateAddSensorQty from '../CreateAddSensorQty/CreateAddSensorQty';
import { Sensor } from '../../Models/Sensor';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';

import { postSite, getAvailableSensors } from '../../Api/Organization';
import SelectMap from '../SelectMap/SelectMap';

//transalate
import { useTranslation} from 'react-i18next';

export type FieldType = {
  nombre?: string;
  descripcion?: number;
  direccion?: string;
  latitud?: string;
  longitud?: string;
  correo?:string;
};

interface Props {
  step?:number
  next?: () => void,
  prev?: () => void,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateSiteComponent = ({step, next, prev, setOpen}:Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [site, setSite] = React.useState<FieldType>({});
  const [sensors, setSensors] = React.useState<Sensor[]>([]);
  const [selectedSensors, setSelectedSensors] = React.useState<Sensor[]>([]);
  const [messageResponse, setMessageResponse] = React.useState<string>("");

  const [correo, setCorreo] = React.useState<string>("");


  const {sendError} = useContext(PromiseSesionContext);



  const onFinishClient = (values: any) => {
    setSite(values)
    setCorreo(values.correo)
    delete values.correo
    next!()
    
  };

  const onFinishMap = (values: {lat: number, lng: number}) => {
    let newSite = site
    newSite.latitud = values.lat.toString()
    newSite.longitud = values.lng.toString()
    setSite(newSite)
    next!()
  };

  const onFinishSensors = async(values: any[]) => {
    try{
      setLoading(true)
      setSelectedSensors(values)
      const res = await postSite(site, values.map((sensor)=>{return sensor.numero_de_serie}), correo)
      setMessageResponse(res.message)
      next!()
      setLoading(false)

    }
    catch(err:any){
      sendError({type: "ERROR", data:{message: err?.response?.data?.message ||t('site.errorCrear')}})
      prev!()
      setLoading(false)
    }
  };

  const back = () => {
    prev!()
  };

  const getData = async () => {
    try{
      setLoading(true)
      const res = await getAvailableSensors()
      if(res) setLoading(false)
      setSensors(res)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})
      setOpen!(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, []);


  return (
    <div>
      <Spiner loading={loading}>
        {step===0 ? <CreateSite onFinishMaster={onFinishClient} defaultValues={site} /> : step===1 ? <SelectMap onFinish={onFinishMap} /> : step===2 ? <CreateAddSensorQty notRequiredSensors sensors={sensors} selectedSensors={selectedSensors} autoAsign onFinishMaster={onFinishSensors} back={back} /> : <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{setOpen!(false)}} />} 
      </Spiner>
    </div>
  )
}

export default CreateSiteComponent

