import { Input } from 'antd'
import React from 'react'

//transalate
import { useTranslation} from 'react-i18next';

interface Props {
    filters: {nombre:string},
    setFilters: React.Dispatch<React.SetStateAction<{nombre:string}>>,
}

const ResellerFilters = ({filters, setFilters}:Props) => {
  const { t } = useTranslation();

    const handleChange = (value: string) => {
        setFilters({...filters, nombre: value})
      };

  return (
    <div style={{padding:"12px", borderBottom:"#f0f0f0 1px solid"}}>
        <Input placeholder={t('basico.placeHolderNombreBuscar')} className='single-Filter' value={filters.nombre} onChange={(event)=>{handleChange(event.target.value)}} />
    </div>
  )
}

export default ResellerFilters