import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ValidateSensors from '../../Components/ValidateSensors/ValidateSensors';
import { MenuItem } from '../../Models/MenuItem';
import { Card, Pagination } from 'antd';
import FilterSimple from '../../Components/FilterSection/FilterSimple';
import SkeletonSensorBasicTable from '../../Components/SkeletonComponents/SkeletonSensorBasicTable';
import { Sensor } from '../../Models/Sensor';
import { getOrganizationsById } from '../../Api/Reseller';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import TableValidate from '../../Components/TableSensor/TablaValidate';
import BackButton from '../../Components/BackButton/BackButton';

//transalate
import { useTranslation } from 'react-i18next';

import "./OrganizationDetail.css"

const OrganizationDetail = () => {
  const [loading, setLoading] = useState(false);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsFilters, setSensorsFilters] = useState<Sensor[]>([]);

  const { sendError } = useContext(PromiseSesionContext);
  const { t } = useTranslation();

  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 6


  const params = useParams();

  const menuItem:MenuItem ={
    label: <span>Crear</span>,
    key: '0',
    title: 'Crear Cliente',
    icon: <></>,
    component: <ValidateSensors id={Number(params.id) || 0} />,
    type: 'MULTICONTENT',
    items: [],
    size: 'small',
    onConfirm: () => {getOrganization()},
  }

  const getOrganization = async()=>{
    try {
      setLoading(true)
      const res = await getOrganizationsById(Number(params.id) || 0)

      setLoading(false)

      setSensorsFilters(res)
      setSensors(res)

    }
    catch{
      setLoading(false)
      sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})
    }
  }


  useEffect(() => {
    getOrganization()
    // findSensorsByAsset(params.id || 0)
  }, []);

  return (
    <div>
      <BackButton text='Organizacion 1'></BackButton>

        <Card extra={ <FilterSimple menuItem={menuItem} title={t('organizacion.validarSensores')} />} style={{height:"calc(100vh - 5rem)", marginTop:"1rem"}} title={t('organizacion.validadorSensores')} bodyStyle={{padding:0, height: "80%", marginTop:"1rem" }}  >
            {/* <SensorFilters filters={filters} setFilters={setFilters} /> */}
            <div className='Layout-Card-Paginate'>
          
            {<SkeletonSensorBasicTable loading={loading} > 
            <div className='OrganizationDetail-table'>
              <TableValidate sensors={sensorsFilters?.slice((page - 1) * sizePages, page * sizePages)} rol={"Reseller"} /> 
            </div>
            </SkeletonSensorBasicTable>}
          
            <div className='centerPaginate'>
              {sensorsFilters.length > sizePages &&<Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={sensors.length} pageSize={sizePages} showSizeChanger={false} />}
            </div>

            </div>
        </Card>
        
    </div>
  )
}

export default OrganizationDetail