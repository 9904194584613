import React, { useContext, useEffect, useState } from 'react'
import { Sensor } from '../../Models/Sensor';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import Spiner from '../Spiner/Spiner';
import AssignSingleSensor from './AssignSingleSensor';
// import { asignSensorToTask, getAvailableSensors } from '../../Api/Subsidiary';
import { ResponseContent } from '../../Models/ResponseContent';
import CreateTask from '../CreateTask/CreateTask';
import { FieldType } from '../CreateTask/CreateTaskComponent';
import { postNewAsignSensor } from '../../Api/Subsidiary';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { getSubsidiaryUnassigned, postNewAsignSensorByOrganization } from '../../Api/Organization';
import { useParams } from 'react-router-dom';

//transalate
import { useTranslation } from 'react-i18next';

interface Props{
    idTask?:string,
    prevInitialValues?:FieldType,
    blockElements?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>, 
    single?:boolean
    asignSensorToTask: (id: string, objeto: any) => Promise<ResponseContent>
    getAvailableSensors: () => Promise<Sensor[]>
    step?:number
    setStep?: React.Dispatch<React.SetStateAction<number>>
  }


const AssignSensorComponent = ({setOpen, idTask, single, asignSensorToTask, getAvailableSensors, step, setStep, prevInitialValues, blockElements}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [sensors, setSensors] = useState<Sensor[]>([])
    const [sensorID, setSensorID] = useState<string>("")

    const [complete, setComplete] = useState<boolean> (false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const [initialValues, setInitialValues] = React.useState<FieldType>(prevInitialValues ? prevInitialValues : {temp:[0, 20]})
    const [errorMessages, setErrorMessages] = React.useState<string>("")
    const {sendError, sesion} = useContext(PromiseSesionContext);
    const params = useParams();


    const getSensors = async () => {
      try{
        setLoading(true)
        const res = sesion.context.rol==="Sucursal" ? await getAvailableSensors() : await getSubsidiaryUnassigned(params.idSucursal || "")
        if(res) setLoading(false)
        setSensors(res)

      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('basico.errorCargaSensores')}})
        setLoading(false)
      }}

    const onFinished = (values:any) => {
        setComplete(true)
        let obj ={
          sensor_ids: [values.sensor_id]
        }
        setSensorID(values.sensor_id)
        setStep!(1)  

    }


    const newAssignTasks = async (obj:any) => {
      try{
        setLoading(true)
        const res = await asignSensorToTask(idTask || "", obj)
        setLoading(false)
        setComplete(true)

      }
      catch(err){
        setLoading(false)
        setComplete(false)
      }
    }

    const createNewTask = async (obj:any) => {
      try{
        setLoading!(true)
  
        sesion.context.rol==="Sucursal" ? await postNewAsignSensor(idTask || "", sensorID , obj) : await postNewAsignSensorByOrganization(idTask || "", sensorID, obj, params.idSucursal || "")
  
        setStep!(2)
        setLoading!(false)
  
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('basico.errorCrearTarea')}})
        setLoading!(false)
      }
    }

    const createTask = (values : any)=>{
      setInitialValues(values)
      if(values.start==="boton"){
        values.start_delayed = {on_button_press: true}

      }
      else{
        values.start_immediately = {}
      }
      values.alarm_high_temp = values.temp![1]
      values.alarm_low_temp = values.temp![0]
      delete values.temp
      delete values.start


      let descripcion = values.descripcion
      delete values.descripcion
      setLoading!(true)
      let obj ={
        task_from_details:{
          task_details: values
        }
      }
      let newObjeto = {
        zebra: obj,
        nombre: values.name,
        descripcion : descripcion
      }
      createNewTask(newObjeto)
    }

    useEffect(() => {
        getSensors();
    }, []);

    useEffect(() => {
      setInitialValues(prevInitialValues ? prevInitialValues : {temp:[0, 20]})
    }, [prevInitialValues]);

    const closeModal = () => {
        setOpen!(false)
    }

  return (
    <div>
      <Spiner loading={loading}>
        {/* {
          complete ? <FinishedSuccess titulo='Sensores asignados correctamente' onFunction={closeModal} /> :
          (single ? <AssignSingleSensor onFinishMaster={onFinished} errorMessages={errorMessage} onClose={closeModal} sensors={sensors} /> :
          <AssignSensor onFinishMaster={onFinished} errorMessages={errorMessage} onClose={closeModal} sensors={sensors}></AssignSensor>)

        } */}

        {
          step===0 ? <AssignSingleSensor onFinishMaster={onFinished} errorMessages={errorMessage} onClose={closeModal} sensors={sensors} /> :
          step===1 ? <CreateTask blockElements={blockElements} onFinishMaster={createTask} initialValues={initialValues} errorMessages={errorMessages} ></CreateTask> :
          <FinishedSuccess titulo={t('sensor.mensaje2')} onFunction={closeModal} />
        }
      </Spiner>
    </div>
  )
}

export default AssignSensorComponent