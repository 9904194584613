import { useContext, useEffect, useState } from 'react'

///test data
import {dataGraphClient, dataGraphAdmin, dataGraphAsset} from '../../DataTest/DataGraph'

//models
import DataGraph from '../../Components/DataGraph/DataGraph'

//grpah
import { Card } from 'antd';
import { Graph } from '../../Models/Graph';
import { LeftOutlined } from '@ant-design/icons';
import SkeletonGraph from '../../Components/SkeletonComponents/SkeletonGraph';
import { PromiseSesionContext } from '../../Machines/SesionMachine';


//api
import { getResellers } from '../../Api/Supplier'
import { Node } from '../../Models/Node';
import { getOrganizations } from '../../Api/Reseller';
import { getSites } from '../../Api/Organization';
import { getAssets } from '../../Api/Subsidiary';
import Resellers from '../Resellers/Resellers';
import DashboardReseller from './DashboardReseller';
import DashboardOrganizacion from './DashboardOrganizacion';
import { Asset } from '../../Models/Asset';
import Assets from '../Assets/Assets';


const Dashboard = () => {
  const [loading,setLoading]= useState<boolean>(false)
  const [tabAlarm, setTabAlarm] = useState<string>("1")
  const [tabGraph, setTabGraph] = useState<string>("1")
  const [data, setData] = useState<Graph>(new Graph([]))
  const [typeGraph, setTypeGraph] = useState<string>("admin")
  const [isTwoLevels, setIsTwoLevels] = useState<boolean>(false)
  const [nonSelected, setNonSelected] = useState<boolean>(false)


  
  const [assets, setAssets] = useState<Asset[]>([]);

  const {sesion} = useContext(PromiseSesionContext);




  const tasksIds=[
    "eae6c13e-c4db-4abf-b362-1d5336093fb8",
    "c1b95253-0b5e-4713-a810-9491a72a2928",
    "19d6c653-c710-4b3d-b6e4-4e79cf3a70ea",
    "7c20721e-0052-446c-8c76-a365ab2cd72a"
]

  const COLORS: string[] = [
    "#1d207f",
    "#f11064",
    "#2e39bf",
    "#11daef",
    "#00fbc1",
    "#f1212c",
    "#fcbe2a",
    "#ff6a00",
    "#ed0fe3",
    "#8711ef",
  ];

  const transformData = (data:any) => {

    let masterNodes:Node[] = []

    for(let i=0; i<data.length; i++){
      const element = data[i]
      let nodes:Node[] = []
      let number = 1

      for (let j = 0; j < element.tarea.length; j++) {
        const element2 = element.tarea[j];
        const color = element2.alarm_count > 0 ? "red" : "green"
        let nodo = new Node(number, element2.sensor.serial_number, "sensor", [], 0,  color)
        nodes.push(nodo)

      }

      let nodo = new Node(element.id, element.nombre, "entidad", nodes)

      masterNodes.push(nodo)

    }

    const resultGraph:Graph = new Graph(masterNodes)
    setData(resultGraph)
  }

  const getData = async () => {
    try{
      setLoading(true)
      let newData:any
      switch (sesion.context.rol) {
        case "Proveedor":
          newData = await getResellers()
          break;
        case "Reseller":
            newData = await getOrganizations()
          break;
        case "Organizacion":
             newData = await getSites()
          break;
        case "Sucursal":
            newData = await getAssets()
          break;
        default:
          break;

    }

    setLoading(false)
    transformData(newData)


    }
    catch(err){ 
    }

  }




  const changeData = (type:string, name:string, save?:boolean) => {
    setLoading(true)
    if(type==="cliente"){
      setData(dataGraphClient)
      setTypeGraph("cliente")

    }

    if(type==="activo"){
      setData(dataGraphAsset)
      setTypeGraph("activo")
      setIsTwoLevels(true)
    }
  }

  const backData = () => {
    if(typeGraph==="cliente"){
      setData(dataGraphAdmin)
      setTypeGraph("admin")
    }

    if(typeGraph==="activo"){
      setData(dataGraphClient)
      setTypeGraph("cliente")
      setIsTwoLevels(false)
    }
  
  }

  const onChangeAlarm = (key: string) => {
    setTabAlarm(key)
  };

  const onChangeGraph = (key: string) => {
    setTabGraph(key)
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
  }, [sesion.context.rol]);


  const renderView = () => {
    if(sesion.context.rol === "Proveedor") return <Resellers />
    if (sesion.context.rol === "Reseller") return <DashboardReseller />
    if (sesion.context.rol === "Organizacion") return <DashboardOrganizacion />
    if (sesion.context.rol === "Sucursal") return <Assets />


    return <></>

  }

    
  return (
    <>
    <>
      {renderView()}
    </>

    {/* <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", gap:"8px", justifyContent:"space-between", alignItems:"stretch"}}>

        {sesion.context.rol === "Sucursal" &&
        <div style={{width:"calc(50% - 4px)"}}>

              <SubsidiaryGraph />

        </div>}

        {sesion.context.rol === "Sucursal" &&
        <div style={{width:"calc(50% - 4px)"}}>
            <Card 
            title={"Alarmas"}
            bodyStyle={{minHeight:"285px"}}
            onTabChange={onChangeAlarm}
            tabList={tasksIds.length===1 ? undefined : (
              tasksIds.map((id, index)=>{
                let name = index===0 ? "Nevera de carnes" : index===1 ? "Nevera de refrescos": index===2 ?  "Cava de medicina (móvil)" : "Demo"
                return {key: (index+1).toString(), tab: `${name}`}
              })
            )}
            >
              <AlertTasks tasksId={tasksIds[(Number(tabAlarm)-1)]}></AlertTasks>
            </Card>
        </div>}

        <div style={{width:"100%"}}>
                 <Resellers />
                 <span>{sesion.context.role}</span>

        </div>

    </div> */}

      {sesion.context.rol === "Sucursal1" &&<div style={{width:"100%"}}>
          <Card bodyStyle={{position:"relative"}} className='dashboardComponentCard'>
              <div
                onClick={backData}
               style={{
                position:"absolute",
                backgroundColor:"#1677ff",
                width:"35px",
                height:"35px",
                flexDirection:"row",
                justifyContent:"center",
                alignItems:"center",
                color:"#fff",
                borderRadius:"3px",
                cursor:"pointer",
                display: typeGraph==="admin" ? "none" : "flex",
              }}>
                <LeftOutlined />
              </div>
              <SkeletonGraph loading={loading} style={{width:"300px", height:"300px"}}> 
              <DataGraph data={data}
              changeData={changeData} 
              colors={COLORS} 
              loading={loading}
              nonSelected={nonSelected}
              isTwoLevels={isTwoLevels} 
              setLoading={setLoading}  />
              </SkeletonGraph>
          </Card>
        </div>}

        {/* <MapApp /> */}
    </>
  )
}

export default Dashboard