import React, { useEffect, useState } from 'react'
import { Dropdown, Modal, type MenuProps } from 'antd';

//styles
import "./Menu.css"

//models
import { MenuItem } from '../../Models/MenuItem';

//components
import ModalContent from '../ModalsContent/ModalContent';
import { BsThreeDotsVertical } from 'react-icons/bs';



interface Props{
  menuItems?:MenuItem[]
  id?:string
  noLocation?:boolean
}

const Menu = ({menuItems, id, noLocation}:Props) => {

  const [items, setItems] = useState<MenuProps['items']>([]);
  const [itemSelected, setItemSelected] = useState<string>("0");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [size, setSize] = useState<string> ("small")

  useEffect(() => {
    if(menuItems){
      let newItems:MenuProps['items'] = []
      for (let index = 0; index < menuItems.length; index++) {
        const element = menuItems[index];
        newItems.push({
          label: element.label,
          key: element.key,
          icon: element.icon,
          onClick: () => {handleMenuClick(element.key)},
        })
      }
      setItems(newItems)
    }
  }, []);

  useEffect(() => {
    setSize(menuItems?.find((item)=>{return item.key===itemSelected})?.size || "small")
  }, [itemSelected]);

  useEffect(() => {
    if(!isModalOpen) handleCancel()
  }, [isModalOpen]);

  const handleMenuClick = (value:string) => {
    setItemSelected(value)
    showModal()
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
        let element = menuItems && menuItems.find((item)=>{return item.key===itemSelected})
        if(element){
          element.onConfirm()
          setIsModalOpen(false);
        }
  };

  const handleCancel = () => {
    let element = menuItems?.find((item)=>{return item.key===itemSelected})
    if(element)element?.onConfirm()

    setIsModalOpen(false);
    setSize(menuItems?.find((item)=>{return item.key===itemSelected})?.size || "small")
  };

  const styles:React.CSSProperties = {
    padding:0,

  }

  const redirectRender =() =>{
    let element = menuItems?.find((item)=>{return item.key===itemSelected})?.component
    let type = menuItems?.find((item)=>{return item.key===itemSelected})?.type

    if(element){
      if(type === "REDIRECT") menuItems?.find((item)=>{return item.key===itemSelected})?.onConfirm()
    }

    return <></>
  }

  const renderElement=() =>{
    let element = menuItems?.find((item)=>{return item.key===itemSelected})?.component
    let type = menuItems?.find((item)=>{return item.key===itemSelected})?.type

    let item = menuItems?.find((item)=>{return item.key===itemSelected})


    if(element){
      if(type === "REDIRECT") menuItems?.find((item)=>{return item.key===itemSelected})?.onConfirm()
      if(type === "MULTILABEL" && item) {
        return <ModalContent menuItem={item} open={isModalOpen} setOpen={setIsModalOpen} />
      }
      return React.cloneElement(element, {setOpen:setIsModalOpen, setSize:setSize, closeModal:handleCancel})
    }
  }

  return (<>
    <Dropdown  menu={{ items }} trigger={['click']}>
      {noLocation ? 
        <BsThreeDotsVertical />
      :
      <div className='Menu-Component'>
          <BsThreeDotsVertical />
      </div>}
    </Dropdown>

      {(menuItems && menuItems.find((item)=>{return item.key===itemSelected})?.type==="CONTENT") ?
        <Modal width={size ==='small' ? undefined : "1000px"} destroyOnClose title={menuItems && menuItems.find((item)=>{return item.key===itemSelected})?.title} style={styles}  onCancel={handleCancel}  bodyStyle={styles}  footer={false}  open={isModalOpen}>
        {renderElement()}
      </Modal>
      :
      (menuItems && menuItems.find((item)=>{return item.key===itemSelected})?.type==="MULTILABEL") ?
      <>
        {renderElement()}
      </>
      :
      <Modal title={menuItems && menuItems.find((item)=>{return item.key===itemSelected})?.title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {redirectRender()}
        {menuItems && menuItems.find((item)=>{return item.key===itemSelected})?.component}
      </Modal>
      }
    </>
  )
}

export default Menu