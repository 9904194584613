import React, { useContext, useEffect, useState } from 'react'
import Spiner from '../Spiner/Spiner'
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { getValidateSensor } from '../../Api/Reseller';
import { ResponseContent } from '../../Models/ResponseContent';

interface Props{
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
    id:number
}

const ValidateSensors = ({setOpen, id}:Props) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState<ResponseContent>({message:"", status_info:"info", content:"", data:[]});

    const {sendError} = useContext(PromiseSesionContext);

    const verify = async ()=>{
        try{
            setLoading(true)
            const res = await getValidateSensor(id)
            setResponse(res)
            setLoading(false)
        }
        catch{
            sendError({type: "ERROR", data:{message:"Error al Validar el sensor"}})
            setOpen!(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        verify();

    }, []);

  return (
    <div>
        <Spiner loading={loading}>
            <FinishedSuccess titulo={response.message} status={response.status_info} onFunction={()=>{setOpen!(false)}} />

            {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
                <Button type="primary" onClick={(e)=>{ setOpen!(false)}} >
                Aceptar
                </Button>
            </div> */}

        </Spiner>
    </div>
  )
}

export default ValidateSensors