import { useEffect, useState } from 'react'
import { stringToDate } from '../Functions/date';
import { generateTempFormat } from '../Functions/temp';

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
  data:any[]
  alarmCount:number
}

const ReportTempDetail = ({data, alarmCount}:Props) => {
  const { t } = useTranslation();
  const [information, setInformation] = useState<{low:number, media:number, last:string, total:number, high:number, first:string, alarmCount:number}>({
    low:0, media:0, last:"-", total:0, high:0, first:"-", alarmCount:0
  });

  const generateInformation =  () =>{

    if(data.length===0) return
    const element = data

    let max = Math.max(...element.map((x:any)=> x.temperature))
    let min = Math.min(...element.map((x:any)=> x.temperature))

    let elementSum: number = element.map((x:any)=> x.temperature).reduce(function(a:any, b:any)
    {
      return Number(a) + Number(b);
    });
    let media = (elementSum/data.length)

    let last=data[0] 
    let first = data[data.length - 1]
    let registers = data.length

    setInformation({
      low:min, media:media, last:( stringToDate(last.timestamp) +" - " + generateTempFormat(last.temperature)), total:registers, high:max, first:(stringToDate(first.timestamp) +" - " + generateTempFormat(first.temperature)), alarmCount:0
    });

  }

  useEffect(() => {
      generateInformation()
    }, [data]);
  
  return (
    <div className='ReportTempDetail ReportLayot'>
        
      <div className='ReportSub-title'>
        <span>{t('activo.detalleTemperatura')}</span>
      </div> 

    <div className='ReportTaskDetail-list'>


        <div className='ReportTaskDetail-sublist'>
          
          <div className='ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.temperaturaBaja')}:</span>
                <span>{generateTempFormat(information.low)}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.temperaturaPromedio')}:</span>
                <span>{generateTempFormat(information.media)}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.ultimaLectura')}:</span>
                <span>{information.first}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.totalRegistros')}:</span>
                <span>{information.total}</span>
            </div>
          </div>

        </div>

        <div className='ReportTaskDetail-sublist'>
          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.temperaturaAlta')}:</span>
                <span>{generateTempFormat(information.high)}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.primeraLectura')}:</span>
                <span>{information.last}</span>
            </div>
          </div>

          <div className='ReportCardSensor-Section ReportCardSensor-SectionBorder'>
            <div className='ReportCardSection-list'>
                <span>{t('activo.conteoAlarmas')}:</span>
                <span>{alarmCount}</span>
            </div>
          </div>

        </div>

    
    </div>

    </div>
  )
}

export default ReportTempDetail