import React, { useState } from 'react'

import { getAvailableSensors,postAddSensors } from '../../Api/Supplier';
import { useNavigate } from 'react-router-dom';

//transalate
import { useTranslation} from 'react-i18next';

import ElementCard from './ElementCard'
import { MenuItem } from '../../Models/MenuItem'
import { PlusOutlined } from '@ant-design/icons'
import EditSensorsComponent from '../EditSensors/EditSensorsComponent'
import { Reseller } from '../../Models/Reseller';
import { BsTextLeft } from 'react-icons/bs';

interface Props{
    reseller: Reseller, 
    propsStyles?: React.CSSProperties  
    elementClass?: string 
}

const ElementCardReseller = ({reseller, propsStyles, elementClass}:Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [menuItems] = useState<MenuItem[]>([
        {
            label: <span>{t('basico.asignarSensores')}</span>,
            title: t('basico.asignarSensores'),
            key: '1',
            icon: <PlusOutlined />,
            component: <EditSensorsComponent type='reseller' id={reseller.id} getFunction={getAvailableSensors} executeFunction={postAddSensors} />,
            type: 'CONTENT',
            items: [],
            size: 'small',
            onConfirm: () => {},
      
          },
          // {
          //   label: <span>Editar Reseller</span>,
          //   title: 'Editar Reseller',
          //   key: '2',
          //   icon: <BsSliders />,
          //   component: <UpdateReseller reseller={reseller} />,
          //   type: 'CONTENT',
          //   items: [],
          //   size: 'small',
          //   onConfirm: () => {},
      
          // },
          {
            label: <span>{t('basico.detalle')}</span>,
            title: t('basico.detalle'),
            key: '3',
            icon: <BsTextLeft />,
            component: <></>,
            type: 'REDIRECT',
            items: [],
            size: 'small',
            onConfirm: () => {navigate("/Reseller/"+reseller.id)},
      
          },

    ]);


      
  return (
    <ElementCard elementClass={elementClass}  sensors={reseller.sensor.length} propsStyles={propsStyles} id={reseller.id} menuItems={menuItems} title={reseller.nombre} description={reseller.descripcion} image='/image/reseller.png' status='' dontShowStatus/>
  )
}

export default ElementCardReseller