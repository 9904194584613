import "./ReportStyles.css"
import ReportHeader from './ReportHeader'
import ReportFooter from './ReportFooter'
import ReportTempList from './ReportTempList'

interface Props {
  data:any
  registers:any
  pages:number
  actualPage:number
}

const ReportTemps = ({data, registers, pages, actualPage}:Props) => {
  return (
    <div className='ReportDetail'>
    <ReportHeader organization={data?.subsidiary?.organizacion?.nombre || []} subsidiary={data?.subsidiary.nombre || []}  />
    
    <div className='ReportTemps-container'>
      <ReportTempList registers={registers} />
    </div>

    <ReportFooter  actualPage={actualPage} pages={pages} email={data.email} />
    </div>
  )
}

export default ReportTemps