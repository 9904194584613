import React from 'react'

//styles
import './LoginForm.css'


import { Button,Form, Input } from 'antd'

//transalate
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons'

interface Props {
    masterLoading:boolean
    onFinishMaster: (values: any) => void

}

const ChangePassword = ({masterLoading, onFinishMaster}:Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const { t } = useTranslation();


    const  onFinish = async (values: FieldType) => {
        onFinishMaster(values)

      };
      
      const onFinishFailed = (errorInfo: any) => {
      };
      
      type FieldType = {
        usuario: string;
        password: string;
        remember?: string;
      };
      
  return (
    <div className='LoginForm-Component'>
        <div>
            <div className='LoginForm-Form'>
                <img src='/image/logo.png'></img>
                <Form
                    className='LoginForm-FormSection'
                    disabled={loading}
                    name="basic"

                    
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">


                    <Form.Item<FieldType>
                    label={t('login.clave')}
                    name="password"
                    rules={[{ required: true, message: t('login.claveError') }]}
                    >
                    <Input prefix={<LockOutlined />} type="password" />
                    </Form.Item>

                    {/* <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                    >
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}


                    <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('basico.aceptar')}
                    </Button>
                    </Form.Item>




                </Form>
                {/* <ButtonPrimary text='Login'></ButtonPrimary> */}
            </div>
        </div>
        <div className='LoginForm-image'>
            <img src='/image/Zebra-Sensores.jpg'></img>

        </div>
    </div>
  )
}

export default ChangePassword


