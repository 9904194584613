import React, { useContext } from 'react'

//styles
import './LoginForm.css'

import { Button,Form, Input } from 'antd'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { useNavigate } from 'react-router-dom'
import {recoverPassword } from '../../Api/Auth'
import { ResponseContent } from '../../Models/ResponseContent'


//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    setRecover:React.Dispatch<React.SetStateAction<string>>
}

const RecoverForm = ({setRecover}:Props) => {
    const {sendSesion, sendError } = useContext(PromiseSesionContext);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const  onFinish = async (values: FieldType) => {
        try{
            setLoading(true)
            sendSesion("VERIFYYING");
            const result:ResponseContent = await recoverPassword(values)
            sendError({type: "ERROR", data:{message:t('basico.recuperarCorreo'), type:"info"}})
            setLoading(false)
            // sendSesion({type: "AUTHORIZED", data:{user: user, rol: user?.tipo}});

        }
        catch(error:any){
            sendSesion("UNAUTHORIZED");
            setLoading(false)
            sendError({type: "ERROR", data:{message:t('basic.errorSolicitud')}})
            return;
        }

      };
      
      const onFinishFailed = (errorInfo: any) => {
      };
      
      type FieldType = {
        usuario: string;
        contrasena: string;
        email:string;
        remember?: string;
      };
      
  return (
    <div className='LoginForm-Component'>
        <div>
            <div className='LoginForm-Form'>
                <img src='/image/logo.png'></img>
                <Form
                    className='LoginForm-FormSection'
                    disabled={loading}
                    name="basic"

                    
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">


                    <Form.Item<FieldType>
                    label={t('basico.correo')}
                    name="email"
                    style={{width:"100%"}}

                    rules={[{ required: true, message: t('basico.errorCorreo') }, {max:50, message:t('basico.max50')}, {type:'email', message:t('basico.formato')}]}
                    >
                    <Input placeholder='email@dominio.com' />
                    </Form.Item>

                    {/* <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                    >
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}





                    <Form.Item >
                    <Button type="primary" htmlType="submit" >
                        {t('basico.enviar')}
                    </Button>
                    </Form.Item>

                    <Form.Item>
                    <Button   onClick={()=>{setRecover("login")}}>
                        {t('login.ingresar')}
                    </Button>
                    </Form.Item>

                </Form>
                {/* <ButtonPrimary text='Login'></ButtonPrimary> */}
            </div>
        </div>
        <div className='LoginForm-image'>
            <img src='/image/Zebra-Sensores.jpg'></img>

        </div>
    </div>
  )
}

export default RecoverForm


