import { useContext, useEffect, useState } from 'react'

//transalate
import { useTranslation } from 'react-i18next';

//styles
import "./Sites.css"
import { Site } from '../../Models/Site'
import { MenuItem } from '../../Models/MenuItem'
import { Card, Pagination } from 'antd'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import CreateSiteComponent from '../../Components/CreateSite/CreateSiteComponent'
import { getSites } from '../../Api/Organization'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import ElementCardSites from '../../Components/ElementCard/ElementCardSites'
import Spiner from '../../Components/Spiner/Spiner'
import ResellerFilters from '../../Components/ResellerFilters/ResellerFilters'
import NoData from '../../Components/NoData/NoData'

const Sites = () => {
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [sitesFilters, setSitesFilters] = useState<Site[]>([]);
  const { error, sendError } = useContext(PromiseSesionContext);
  const [filters, setFilters] = useState<{nombre:string}>({nombre:""}); //filters
  const {sesion, sendSesion } = useContext(PromiseSesionContext);

  const { t } = useTranslation();

    //paginate
    const [page, setPage] = useState<number>(1)
    const sizePages = 12

    const items=[
      {
        title: t('basico.informacion'),
      },
      {
        title:t('basico.ubicacion'),
      },
      {
        title: t('basico.sensores'),
      },
      {
        title: t('basico.detalle'),
      },
    ]

    const menuItem:MenuItem ={
      label: <span>Crear</span>,
      key: '0',
      title: 'Crear Cliente',
      icon: <></>,
      component: <CreateSiteComponent />,
      type: 'MULTICONTENT',
      items: items,
      size: 'small',
      onConfirm: () => {getData()},
    }

    const getData = async () => {
      try{
      setLoading(true)
      const res = await getSites()

      setSites(res)
      setSitesFilters(res)
      setLoading(false)
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('site.error')}})
        setLoading(false)

      }
  
    }

    const filterByName = ()=>{
      setSitesFilters(sites.filter((s:Site) => s.nombre.toUpperCase().includes(filters.nombre.toUpperCase())))
  
    }
  
    useEffect(() => {
      getData();
    }, []);

    useEffect(() => {
      if(filters.nombre!=""){
        filterByName()
      }
      else{
        setSitesFilters(sites)
      }
    }, [filters]);

  return (
    <div>
    <Card title={t('site.titulo')} className='SiteCard' bodyStyle={{padding:"12px", height:"82%"}} extra={sesion.context.user.rol==="admin" &&<FilterSimple menuItem={menuItem} />}>
        <Spiner loading={loading}>
        <ResellerFilters filters={filters} setFilters={setFilters} />

          <NoData data={sitesFilters} text={t('site.sinDatos')}> 
            <div className='Layout-Card-Paginate'>

                <div className='sitesPage-list' style={{paddingTop:"12px"}}>        
                    {sitesFilters?.slice((page - 1) * sizePages, page * sizePages).map((site, index) => {
                      return (
                        <ElementCardSites elementClass='Card-Full-Width' refresh={getData}   site={site} />
                      )
                    })}

                </div>

                <div className='centerPaginate'>
                  {sitesFilters.length > sizePages && <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page}  total={sitesFilters.length} pageSize={sizePages} showSizeChanger={false} />}
                </div>
              </div>
          </NoData>
        </Spiner>
    </Card>

  </div>
  )
}

export default Sites