import React, { useContext, useEffect, useState } from 'react'
import CreateAddSensorQty from '../CreateAddSensorQty/CreateAddSensorQty'
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { Sensor } from '../../Models/Sensor';
import { ResponseContent } from '../../Models/ResponseContent';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

//transalate
import { useTranslation} from 'react-i18next';

interface Props{
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
    type?: "supplier" | "reseller"
    id?: number
    getFunction?: () => Promise<Sensor[]>
    executeFunction?: (id:number, sensors:Sensor[] ) => Promise<ResponseContent>

}

const EditSensorsComponent = ({setOpen, getFunction, executeFunction, id}:Props) => {
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([]);

    const { t } = useTranslation();

      //paginate
    const [page, setPage] = useState<number>(1)
    const sizePages = 6

    const {sendError} = useContext(PromiseSesionContext);


    const closeModal =() =>{
        setOpen!(false)
    }

    const asignedSensors = (sensors:Sensor[]): Sensor[] =>{
        return sensors.filter(sensor => sensor.reseller!==null)
    }

    const getData = async () => {
        try{
        setLoading(true)
        const res= await getFunction!()
        setLoading(false)
        setSensors(res)
        // setSelectedSensors(asignedSensors(res))

        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('basico.errorRegistros')}})
            closeModal()
            setLoading(false)
        }

    }

    useEffect(() => {
        getData();

    }, []);

    const onFinishMaster = async (values:any) => {
        try{
            setLoading(true)
            let res= await executeFunction!(id||0, values.map((sensor:any)=>{return sensor.numero_de_serie}))
            if(res) setLoading(false)
            setComplete(true)
        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('basico.errorAsignar')}})
            setLoading(false)
        }
    }


  return (
    <div>
        <Spiner loading={loading}>
            {complete ? <FinishedSuccess titulo={t('basico.actualizacionCorrecta')}  onFunction={closeModal} /> : <CreateAddSensorQty sensors={sensors} selectedSensors={selectedSensors} back={closeModal} onFinishMaster={onFinishMaster} />}
        </Spiner>
    </div>
  )
}

export default EditSensorsComponent