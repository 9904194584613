import { Apikey } from '../../Models/Apikey';
import Table, { ColumnsType } from 'antd/es/table';
import ModalButton from '../ModalButton/ModalButton';
import { BsPencilSquare } from 'react-icons/bs';
import { MenuItem } from '../../Models/MenuItem';
import { TagOutlined } from '@ant-design/icons';
import UpdateApikey from '../UpdateApikey/UpdateApikey';

//transalate
import { useTranslation } from 'react-i18next';



interface Props{
  apikeys:Apikey[]
}


const TableResellerApikey = ({apikeys}:Props) => {
  const { t } = useTranslation();

  const columns: ColumnsType<Apikey> = [
    {
        title:t('basico.nombre'),
        dataIndex: 'numero_de_serie',
        key: 'numero_de_serie',
        render: (_, {nombre}) =>{ 

          return  <>{nombre || "Sin asignar"}</>
        },
      },
      {
        title: t('basico.tipo'),
        dataIndex: 'tipo',
        key: 'tipo',
        render: (_, {tipo}) =>{ 

          return <>{tipo || "Sin asignar"}</>
        },
    },  
    {
        title: t('basico.token'),
        dataIndex: 'token',
        key: 'token',
        responsive:["sm"]
    },    
    {
      title: t('basico.organizacion'),
      key: 'organizacion',
      render: (_, record) => {

            return <>{record.org_nombre || t('basico.sinAsignar')           }</>
            }
      ,
    },

    {
      title: t('basico.acciones'),
      key: 'action',
      render: (_, {tipo, nombre, token}) =>{ 
        let menuItem:MenuItem =   {
          label: <span>Sensores</span>,
          title: t('basico.editarApikey'),
          key: '1',
          icon: <TagOutlined />,
          component: <UpdateApikey nombre={nombre} tipo={tipo} token={token} />,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {},
    
        }
        return  <ModalButton menuItem={menuItem}  icon={<BsPencilSquare />}></ModalButton>
      }
      ,
    },
  ];

  return (
    <Table scroll={{x:100}}  pagination={false} columns={columns} dataSource={apikeys} />

  )
}

export default TableResellerApikey