import React, { useContext } from 'react'

//styles
import './LoginForm.css'
import { Button, Form, Input } from 'antd'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { login } from '../../Api/Auth'
import { ResponseContent } from '../../Models/ResponseContent'

import jwt from 'jwt-decode' 
import { User } from '../../Models/User'

//transalate
import { useTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons'

interface Props {
    setRecover:React.Dispatch<React.SetStateAction<string>>
}

const LoginForm = ({setRecover}:Props) => {
    const { sendSesion, sendError } = useContext(PromiseSesionContext);
    const [loading, setLoading] = React.useState<boolean>(false);

    const { t } = useTranslation();


    const  onFinish = async (values: FieldType) => {
        try{
            setLoading(true)
            sendSesion("VERIFYYING");
            const result:ResponseContent = await login(values.usuario, values.contrasena)

            if(result){
                localStorage.setItem("token", result.content);
                const user:User = jwt(localStorage.getItem("token") || "")
                // if(user.id_entidad===12 && user.tipo=="Organizacion"){
                //     localStorage.setItem("expired", "true");
                // }
                sendSesion({type: "AUTHORIZED", data:{user: user, rol: user?.tipo}});
                setLoading(false)
            }
        }
        catch(error:any){
            sendSesion("UNAUTHORIZED");
            setLoading(false)
            if(error.code == 'ERR_NETWORK') {
                sendError({type: "ERROR", data:{message:t('login.errorConexion')}})
                return
            }
            sendError({type: "ERROR", data:{message:t('login.errorClave')}})
            return;
        }
      };
      
      const onFinishFailed = (errorInfo: any) => {
      };
      
      type FieldType = {
        usuario: string;
        contrasena: string;
        remember?: string;
      };
      
  return (
    <div className='LoginForm-Component'>
        <div>
            <div className='LoginForm-Form'>
                <img src='/image/logo.png'></img>
                <Form
                    className='LoginForm-FormSection'
                    disabled={loading}
                    name="basic"

                    
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Form.Item<FieldType>
                    label={t('login.usuario')}
                    name="usuario"
                    rules={[{ required: true, message: t('basico.errorUsuario') }]}
                    >
                    <Input prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item<FieldType>
                    label={t('login.clave')}
                    name="contrasena"
                    rules={[{ required: true, message: t('login.claveError') }]}
                    >
                    <Input prefix={<LockOutlined />} type="password" />
                    </Form.Item>

                    {/* <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                    >
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}


                    <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('login.ingresar')}
                    </Button>
                    </Form.Item>


                    <Form.Item >
                    <Button onClick={()=>{setRecover("Recuperar")}} >
                        {t('basico.recuperarContrasena')}
                    </Button>
                    </Form.Item>

                </Form>
                {/* <ButtonPrimary text='Login'></ButtonPrimary> */}
            </div>
        </div>
        <div className='LoginForm-image'>
            <img src='/image/Zebra-Sensores.jpg'></img>

        </div>
    </div>
  )
}

export default LoginForm


