import { Input } from 'antd'
import React from 'react'

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
    filters: {nombre:string},
    setFilters: React.Dispatch<React.SetStateAction<{nombre:string}>>,
    notShowAsign ?: boolean
}

const ApikeyFilters = ({filters, setFilters, notShowAsign}:Props) => {
    const { t } = useTranslation();
    const changeText = (value:string) => {
        setFilters({...filters, nombre: value})
    
      }

  return (
    <div style={{padding:"12px", borderBottom:"#f0f0f0 1px solid"}}>
      <Input onChange={(e)=>{changeText(e.target.value)}} placeholder={t('basico.buscarPorRevendedor')} className='single-Filter' />
    </div>
  )
}

export default ApikeyFilters