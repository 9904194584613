import React, { useContext, useState } from 'react'
import Table, { ColumnsType } from 'antd/es/table'
import ButtonIcon from '../ButtonIcon/ButtonIcon';

//transalate
import { useTranslation } from 'react-i18next';

import { CheckOutlined, CloseOutlined, EyeOutlined, TagOutlined } from '@ant-design/icons'

//css 
import './TableRequest.css'
import Button from 'antd/es/button';
import { Modal, Tag } from 'antd';
import { Request } from '../../Models/Request';
import ResponseRequest from '../ResponseRequest/ResponseRequest';
import { patchConfirmRequest } from '../../Api/Supplier';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

interface Props{
  requests: Request[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  getData: ()=> void
}

const TableRequest = ({requests, setLoading, getData}:Props) => {
  const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [openRequest, setOpenRequest] = useState<boolean>(false);

    const [sensores, setSensores] = useState<string[]>([]);

    const [response, setResponse] = useState<{ status: boolean, id:number|null, idReseller:number|null}>({status:false , id:null, idReseller:null});

    const {sendError} = useContext(PromiseSesionContext);


    const confirmRequestMethod = async(idRequest:number , idReseller:number) =>{
        try{
            setLoading(true)
            const res = await patchConfirmRequest(idRequest, idReseller)
            setLoading(false)
            sendError({type: "ERROR", data:{message:t('solicitud.mensajeAceptado'), type:"success"}})
            getData()
          }
          catch(err){
            sendError({type: "ERROR", data:{message:t('solicitud.errorAceptarSolicitud')}})
            setLoading(false)
          }
    }


    const columns: ColumnsType<Request> = [
    { title: t('basico.revendedor'), dataIndex: 'name', key: 'name',render: (_, record) => {
      return <>{record?.reseller?.nombre}</>
    }, },
    { title: t('basico.estatus'), dataIndex: 'status', key: 'address', render:(_, record) => <Tag color={record.status==="Pendiente" ?"orange" : record.status==="Aprobada" ? "blue" :"red"} > {record.status}</Tag> },

    { title: t('basico.sensores'), dataIndex: 'sensores', key: 'sensores', render:(_, record) => <>{record.sensores.length} {record.sensores.length > 1 ? t('basico.sensores') : t('basico.sensor')}</> },
    { title: t('basico.maximoSensores'), dataIndex: 'address', key: 'address', render:(_, record) => <> {record.reseller.cantidad_sensores} {record.reseller.cantidad_sensores > 1 ? t('basico.sensores') : t('basico.sensor')} </> },


    {
      title: t('basico.detalle'),
      dataIndex: 'status',
      key: 'detail',
      render: (_, record) => <div className='TableRequest-SensorsSection'>

      <Button type="text" onClick={()=>{setOpen(true);setSensores(record.sensores)}}>
      <EyeOutlined />
      </Button>
      </div>,
    },

    {
      title: t('basico.acciones'),
      dataIndex: '',
      key: 'x',
      render: (_, record) => {
      if(record.status==="Rechazada" ||record.status==="Aprobada") return <></>
      return <div className='TableRequest-SensorsSection'>

        <Button
        
          type="primary"
          icon={<CheckOutlined />}
          onClick={()=>{
            confirmRequestMethod(record.id, record.reseller.id)
          }}
    
        />
        <Button
          danger
          type="primary"
          icon={<CloseOutlined />}

          onClick={()=>{
            setResponse({
              status:false,
              id:record.id,
              idReseller:record.reseller.id
            })
            setOpenRequest(true)
          }}
    
        />

      </div>},
    },


  ];


  return (
    <>
      <Table
      columns={columns}
      scroll={{x:100}}
      pagination={false}
      dataSource={requests}/>

      <Modal title={t('solicitud.tituloModal1')} open={open} onOk={()=>{}} onCancel={()=>{setOpen(false)}} footer={false} bodyStyle={{padding:"20px 0"}} >
        <div className='TableRequest-sensors' >
          {sensores.map((s)=> <ButtonIcon icon={<TagOutlined />} text={s} />)}
          
        </div>
      </Modal>

      <Modal title={t('solicitud.tituloModal2')} open={openRequest} onOk={()=>{}} onCancel={()=>{setOpenRequest(false)}} footer={false} bodyStyle={{padding:"20px 0"}} >
        <ResponseRequest onClose={()=>{setOpenRequest(false); getData()}} response={response} />
      </Modal>
    </>

  )
}

export default TableRequest