import BatteryIndicator from '../Components/BatteryIndicator/BatteryIndicator'

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
    serial:string,
    model:string,
    batery:number
}

const ReportCardSensor = ({serial, model, batery}:Props) => {
    const { t } = useTranslation();
  return (
    <div className='ReportCardSensor'>

        <div className='ReportCardSection ReportCardSensor-title'>
            <span>{serial}</span>
        </div>
        
        <div className='ReportCardSensor-Section'>
            <div className='ReportCardSection-list'>
                <span>{t('basico.modelo')}:</span>
                <span>{model}</span>
            </div>
            <div className='ReportCardSection-list'>
                <span>{t('basico.bateria')}:</span>
                <span><BatteryIndicator battery={batery}></BatteryIndicator></span>
            </div>
        </div>

    </div>
  )
}

export default ReportCardSensor