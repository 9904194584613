import { Input } from 'antd'
import React from 'react'

//transalate
import { useTranslation } from 'react-i18next';

import { DatePicker} from 'antd';
const { RangePicker } = DatePicker;

interface Props {
    filters: {nombre:string, dates:any[]},
    setFilters: React.Dispatch<React.SetStateAction<{nombre:string, dates:any[]}>>,
}

const NameDateFilter = ({filters, setFilters}:Props) => {
  const { t } = useTranslation();
    const handleChange = (value: string) => {
        setFilters({...filters, nombre: value})
      };

    const ChangeDate = (value: any[]) => {
        if(value)setFilters({...filters, dates: value})
            else setFilters({...filters, dates: []})
      };


  return (
    <div style={{padding:"12px", borderBottom:"#f0f0f0 1px solid"}}>
        <Input placeholder={t('basico.placeHolderNombreBuscar')} value={filters.nombre} className='single-Filter' onChange={(event)=>{handleChange(event.target.value)}} />

        <RangePicker placeholder={[t('basico.inicio'), t('basico.fin')]}  style={{marginLeft:"1rem"}} onChange={(x:any)=>{ChangeDate(x)}} />
    </div>
  )
}

export default NameDateFilter