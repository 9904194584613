import { useState } from 'react'
import Spiner from '../Spiner/Spiner';
import { Button, Result } from 'antd';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { deleteNotificationContact } from '../../Api/Subsidiary';
import { useTranslation } from 'react-i18next';

interface Props {
    closeModal?: () => void
    id?:number
}

const RemoveNotificationContact = ({closeModal, id}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)
    const [message, setMessage] = useState<string> (t('notificacion.eliminado'))

    const finish = async() => {
        try{
          setLoading(true)
          const res=  await deleteNotificationContact(id || 0)
          setLoading(false)
          setComplete(true)
    
        }
        catch(err:any){
            setMessage(err.response.data.message || t('basico.errorTransaccion'))
            setLoading(false)
            setComplete(true)
        }
    
      }
    
  return (
    <div>
        <Spiner loading={loading}>
        {!complete &&
        <>
        <Result
        status="info"
        title={t('notificacion.mensajeRemover')}
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Button type='default' onClick={()=>{closeModal!()}}>
            {t('basico.cancelar')}
          </Button>

          <Button type='primary' onClick={()=>{finish()}}>
            {t('basico.aceptar')}
          </Button>
        </div>
        </>
        }

        {complete&&
        <FinishedSuccess titulo={message}  onFunction={closeModal} /> 
        }
        </Spiner>
    </div>
  )
}

export default RemoveNotificationContact