import { useContext, useEffect, useState } from 'react'
import SingleCardInformation from '../../Components/SingleCardInformation/SingleCardInformation'
import { Card } from 'antd'
import { getSensors as getsensorsReseller } from '../../Api/Reseller'
import { getOrganizations } from '../../Api/Reseller'

//transalate
import { useTranslation} from 'react-i18next';

//style
import './Dashboard.css'

import SensorFilters from '../../Components/SensorFilters/SensorFilters'
import { MenuItem } from '../../Models/MenuItem'
import CreateClientComponent from '../../Components/CreateClient/CreateClientComponent'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { Sensor } from '../../Models/Sensor'
import SkeletonSensorBasicTable from '../../Components/SkeletonComponents/SkeletonSensorBasicTable'
import { Organization } from '../../Models/Organization'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import ElementCardOrganization from '../../Components/ElementCard/ElementCardOrganization'
import { createSimpleCardInfoElements } from '../../Functions/transform'
import Spiner from '../../Components/Spiner/Spiner'
import NoData from '../../Components/NoData/NoData'
import CreateSensors from '../../Components/CreateSensors/CreateSensors'
import TableSensorReseller from '../../Components/TableSensor/TableSensorReseller'

interface Elements {
  title: string;
  content: string;

}

const DashboardReseller = () => {
    const [filters, setFilters] = useState<{status:string, nombre:string}>({status:"", nombre:""}); //filters
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const [sensorsFilters, setSensorsFilters] = useState<Sensor[]>([]);

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const { sendError } = useContext(PromiseSesionContext);

    const [cardInfoElementsSensor, setCardInfoElementsSensor] = useState<Elements[]>([]); //filters
    const [cardInfoElementsOrganizations, setCardInfoElementsOrganizations] = useState<Elements[]>([]); //filters

    const { t } = useTranslation();

    //loading
    const [loading, setLoading] = useState(false);

    let styleCard ={
        width: "32%",
      }

      const items=[
        {
          title: t('basico.informacion'),
        },
        {
          title: t('basico.sensores'),
        },
        {
          title: t('basico.usuario'),
        },
      ]
    
      const menuItem:MenuItem ={
        label: <span>Crear</span>,
        key: '0',
        title: t('organizacion.crearOrganizacion'),
        icon: <></>,
        component: <CreateClientComponent  />,
        type: 'MULTICONTENT',
        items: items,
        size: 'small',
        onConfirm: () => {getData(); getSensors()},
      }


      const itemSensor=[
        {
          title: t('organizacion.definirSensores'),
        },
        {
          title: t('basico.detalle'),
        },
      ]

      const menuItemSensors:MenuItem ={
        label: <span>Cargar sensores</span>,
        key: '0',
        title: 'Agregar sensores',
        icon: <></>,
        component: <CreateSensors write />,
        type: 'MULTICONTENT',
        items: itemSensor,
        size: 'small',
        onConfirm: () => {getSensors()},
      }

      const getSensors = async () => {
        try{
          setLoading(true)
          let newSensor:Sensor[] = []
          newSensor = await getsensorsReseller()

          setSensors(newSensor)
          setSensorsFilters(newSensor)
          setLoading(false)

          setCardInfoElementsSensor(createSimpleCardInfoElements("organizacion", newSensor, t('basico.sensoresAsignados'), t('basico.sensoresNoAsignados')))
    
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('basico.errorCargaSensores')}})
        setLoading(false)
      }
    
      }

      const getData = async () => {
        try{
        setLoading(true)
        const res = await getOrganizations()


        setOrganizations(res)
        setLoading(false)
        setCardInfoElementsOrganizations([{title:t('basico.organizaciones'), content:res.length.toString()}])
        // setResellers(res)
        }
        catch(err){
          sendError({type: "ERROR", data:{message:t('basico.errorOrganizaciones')}})
          setLoading(false)
        }
    
      }

      useEffect(() => {
        getSensors()
        getData();
      }, []);

  const styles = {
    width: "48%",
  }

  const filterBySerial = ()=>{
    if(filters.nombre!=""){
      setSensorsFilters(filterByStatus(sensors).filter((x:Sensor) => x.numero_de_serie.includes(filters.nombre)))
    }
    else{
      setSensorsFilters(filterByStatus(sensors))
    }
  }

  const filterByStatus = (sensorArray:Sensor[]):Sensor[] =>{

    if(filters.status=="Asignados"){

      return sensorArray.filter((sensor:Sensor) => sensor.reseller || sensor.organizacion)
    }
    else if(filters.status=="Sin asignar"){

      return sensorArray.filter((sensor:Sensor) => !sensor.reseller && !sensor.organizacion)
    }
    else{
      return sensorArray
    }
  }

  useEffect(() => {
    if(sensors.length>0){
      filterBySerial()
    }
  }, [filters]);

  useEffect(() => {
  }, [sensorsFilters]);

  const onGetData = ()=>{
    getData()
    getSensors()
  }

  return (
    <div className='DashboardReseller-layout'>
        <div className='DashboardReseller-layout-one' >
            <Card  title={t('basico.organizaciones')} className="DashboardReseller-card-organization" bodyStyle={{padding:"12px", height:"92%", overflowY:"hidden"}} extra={<FilterSimple menuItem={menuItem} />}  >
                {/* <ElementCard/> */}
                <Spiner loading={loading} >
                  <NoData data={organizations} text={t('basico.noOrganizaciones')}> 
                    <div className='DashboardReseller-organization-list scrollbar-component' >        
              
                        {organizations.map((organization, index) => {
                          return (
                            <ElementCardOrganization elementClass='Card-middle-Width' onGetData={onGetData}  organization={organization} key={index} />

                          )
                        })}

                    </div>
                  </NoData>
                </Spiner>
            </Card>
        </div>

        <div className='DashboardReseller-layout-two'>
            <div className='fullListCardInformation DashboardReseller-cards-info'> 
                {!loading &&
                  cardInfoElementsSensor.map((element, index) => {
                    return <SingleCardInformation elementClass='Card-dreseller-cards' title={element.title} value={element.content} />

                  } )
                }

                {!loading &&
                  cardInfoElementsOrganizations.map((element, index) => {
                    return <SingleCardInformation elementClass='Card-dreseller-cards' title={element.title} value={element.content} />

                  } )
                }

                {loading &&
                  (<>
                    <SingleCardInformation elementClass='Card-dreseller-cards' title={t('basico.sensoresAsignados')} value={""} loading />
                    <SingleCardInformation elementClass='Card-dreseller-cards' title={t('basico.sensoresNoAsignados')} value={""} loading />
                    <SingleCardInformation elementClass='Card-dreseller-cards' title={t('basico.organizaciones')} value={""} loading />
                  </>)
                }
            </div>


            <Card extra={<FilterSimple menuItem={menuItemSensors} title={t('basico.cargarSensores')} />} title={t('basico.sensores')} bodyStyle={{padding:0, overflowY:"hidden", height:"90%"}} className='DashboardReseller-card-sensor'  >
              <SensorFilters filters={filters} setFilters={setFilters} />
            
              <SkeletonSensorBasicTable loading={loading} > <div className='scrollbar-component DashboardReseller-table-sensors'><TableSensorReseller sensors={sensorsFilters} rol={"Reseller"} /></div> </SkeletonSensorBasicTable>
            </Card>

        </div>

    </div>
  )
}

export default DashboardReseller