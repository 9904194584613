import { useContext, useEffect, useState } from 'react'
import CreateAssetComponent from '../../Components/CreateAsset/CreateAssetComponent'
import { MenuItem } from '../../Models/MenuItem'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { Card, Pagination } from 'antd'
import { Asset } from '../../Models/Asset'
import { getAssets } from '../../Api/Subsidiary'
import Spiner from '../../Components/Spiner/Spiner'
import NoData from '../../Components/NoData/NoData'
import ElementCardAsset from '../../Components/ElementCard/ElementCardAsset'
import ResellerFilters from '../../Components/ResellerFilters/ResellerFilters'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { getAssetsDetail } from '../../Api/Organization'
import { useParams } from 'react-router-dom'

//transalate
import { useTranslation, Trans } from 'react-i18next';
import NewElementCards from '../../Components/ElementCard/NewElementCards'

const Assets = () => {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [assetsFilters, setAssetsFilters] = useState<Asset[]>([]);

  const [filters, setFilters] = useState<{nombre:string}>({nombre:""}); //filters
  const params = useParams();

  const {sendError, sesion} = useContext(PromiseSesionContext);

  const { t } = useTranslation();

  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 12


  const items=[
    {
      title: t('activo.informacion'),
    },
    {
      title: t('activo.caracteristicas'),
    },
    {
      title: t('basico.detalle'),
    },
  ]

  const menuItem:MenuItem ={
    label: <span>Crear</span>,
    key: '0',
    title: 'Crear Cliente',
    icon: <></>,
    component: <CreateAssetComponent />,
    type: 'MULTICONTENT',
    items: items,
    size: 'small',
    onConfirm: () => {getData()},
  }

  const getData = async () => {
    try{
    setLoading(true)
    let res = sesion.context.rol==="Sucursal" ? await getAssets() : await getAssetsDetail(params.idSucursal || "")

    res = res.sort((a:Asset,b:Asset)=>{

      if(a.tarea.length > b.tarea.length){
        return -1
      }
      return 1
    })

    setAssets(res)
    setAssetsFilters(res)
    setLoading(false)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('activo.errorCarga')}})
      setLoading(false)
    }

  }

  const filterByName = ()=>{
    setAssetsFilters(assets.filter((a:Asset) => a.nombre.toUpperCase().includes(filters.nombre.toUpperCase())))

  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(filters.nombre!=""){
      filterByName()
    }
    else{
      setAssetsFilters(assets)
    }
  }, [filters]);

  return (
    <div>
        <Card title={t('activo.titulo')} style={{height:"calc(100vh - 2rem)"}} bodyStyle={{padding:"0", height:"90%"}} extra={sesion.context.user.rol==="admin" && <FilterSimple menuItem={menuItem} />}>
            <Spiner loading={loading} >
                <NoData data={assets} text={t('activo.sinActivos')}>
                  <div className='Layout-Card-Paginate'>
                  <ResellerFilters filters={filters} setFilters={setFilters} />

                      <div className='ResellerPage-list' style={{padding:"12px"}}>   
                          {assetsFilters?.slice((page - 1) * sizePages, page * sizePages).map((asset, index) => {
                            return (
                              <ElementCardAsset elementClass="Card-Full-Width" refresh={getData} action={()=>{}}  asset={asset} />
                            )
                          })}
                      </div>

                      <div className='centerPaginate'>
                        {assetsFilters.length >sizePages && <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={assetsFilters.length} pageSize={sizePages} showSizeChanger={false} />}
                      </div>
                  </div>
                </NoData>
              </Spiner>
        </Card>
    </div>
  )
}

export default Assets