import React, { useContext, useEffect} from 'react'

//Styles
import './Layout.css'

//Components
import NavBar from '../Components/NavBar/NavBar'

//Machines
import { PromiseSesionContext } from '../Machines/SesionMachine'
import { useNavigate } from 'react-router-dom'
import NavbarMovil from '../Components/NavBar/NavbarMovil'
import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill';


import { message } from 'antd';

interface Props {
    children: React.ReactNode
}

const Layout = ({children}:Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const {sesion} = useContext(PromiseSesionContext);
  const navigate = useNavigate();

  const onClickAlarm = (data:any) =>{

    sesion.context.rol==="Sucursal" ? navigate("/Asset/"+data?.sensor?.activo_id.toString()) : navigate("/Asset/"+data?.sensor?.activo_id.toString()+'/'+ (data?.sensor?.sucursal_id|| "") )
  }

  var reconnectFrequencySeconds = 1;
  var evtSource;

  // Putting these functions in extra variables is just for the sake of readability
  var waitFunc = function() { return reconnectFrequencySeconds * 1000 };
  var tryToSetupFunc = function() {
      setupEventSource();
      reconnectFrequencySeconds *= 2;
      if (reconnectFrequencySeconds >= 64) {
          reconnectFrequencySeconds = 64;
      }
  };

  var reconnectFunc = function() { setTimeout(tryToSetupFunc, waitFunc()) };

  const setupEventSource = ()=> {
    try{
    const source = new EventSourcePolyfill(`https://api.idsense.app/thermotrack/${sesion.context.rol=="Organizacion" ? "organization" : "subsidiary"}/sse`,{headers: {Authorization: `Bearer ${localStorage.getItem("token") || ""}`}, heartbeatTimeout: 600000 ,});
    source.addEventListener("message", (event:MessageEvent) => {
      try{
        const data = JSON.parse(event.data);

        messageApi.open({
          type: 'error',
          content: `Alarma en el sensor ${data?.sensor?.numero_de_serie} en el activo ${data?.sensor?.activo_nombre}`,
          onClick(e) {
            onClickAlarm(data)
            
          },
        });
      }
      catch(error){
      }

      source.onopen = ()=>{
        reconnectFrequencySeconds = 1;
      }

    })

    source.onerror = (error) => {
      // error log here 

      source.close();
      reconnectFunc();
    }
    return () => {
      source.close();
    };

    }
    catch (err){
      
    }
  }

  useEffect(() => {
    if(sesion.value!=="authorized" && (sesion.context.rol!=="Organizacion" || sesion.context.rol!=="Sucursal" )) return 

    setupEventSource()
  }, [sesion]);

  return (
    <div className='Layout-component'>
        {contextHolder}
        {sesion.value==="authorized" && <NavBar />}
        <div className={sesion.value==="authorized" ? 'Layout-children' : "Layout-children-Complete"}>
            {children}
        </div>
        {sesion.value==="authorized" && <NavbarMovil />}
    </div>
  )
}

export default Layout