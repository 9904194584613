import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import ChangeRolForm from './ChangeRolForm';
import { postChangeRole } from '../../Api/Reseller';
import { postChangeRoleOrganization } from '../../Api/Organization';
import { postChangeRoleSubsidiary } from '../../Api/Subsidiary';

export type FieldType = {
    rol_id?: number;
  };

  interface Props{
    rol_id:number
    userID:number
    closeModal?:()=>void
    refresh: () => Promise<void>
}

const ChangeRol = ({rol_id, userID, closeModal, refresh}:Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0);
    const [messageResponse, setMessageResponse] = React.useState<string>("");
    const { t } = useTranslation();

    const {sendError, sesion} = useContext(PromiseSesionContext);

    const onFinish= async (values:any)=>{
        try{
            setLoading(true)
            const res = sesion.context.rol==="Reseller" ? await postChangeRole(userID,values) : sesion.context.rol==="Organizacion"? postChangeRoleOrganization(userID,values): postChangeRoleSubsidiary(userID,values)
            setMessageResponse(t('usuario.updatedUser'))
            setStep(1)
            setLoading(false)
      
          }
          catch(err){
            sendError({type: "ERROR", data:{message:t('basico.errorActualizarDatos')}})
            setLoading(false)
          }
      }
    
  return (
    <div>
        <Spiner loading={loading}>
            {step === 0 ?
            <ChangeRolForm defaultValues={{rol_id:rol_id}} onFinishMaster={onFinish}/>
            :
            <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{refresh(); closeModal!() }} />
            }
        </Spiner>
    </div>
  )
}

export default ChangeRol