import { Button, Result } from 'antd'
import { useContext, useState } from 'react'
import Spiner from '../Spiner/Spiner';
import { removeSensor } from '../../Api/Organization';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { removeSensorFromOrganization } from '../../Api/Reseller';

//transalate
import { useTranslation} from 'react-i18next';

interface Props {
    closeModal?: () => void
    serialSensor: string,
    idSubsidiary?:number
}
const RemoveSensor = ({closeModal, serialSensor, idSubsidiary}:Props) => {
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)
    const {sesion, sendSesion } = useContext(PromiseSesionContext);
    
    const { t } = useTranslation();
    const [message, setMessage] = useState<string> (`${t('sensor.mensaje1')} ${(sesion.context.rol==="Organizacion") ? (t('basico.sucursal')) : t('basico.organizacion')} `)

      const finish = async() => {
        try{
          setLoading(true)
          const res= (sesion.context.rol==="Organizacion") ? await removeSensor(serialSensor, idSubsidiary || 0) : await removeSensorFromOrganization(serialSensor)
          
          setLoading(false)
          setComplete(true)
    
        }
        catch(err:any){
            setMessage(err.response.data.message || t('basico.errorTransaccion'))
            setLoading(false)
            setComplete(true)
        }
    
      }

  return (
    <div>
        <Spiner loading={loading}>
        {!complete &&
        <>
        <Result
        status="info"
        title={t('basico.mensajeEliminarSensor')}
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Button type='default' onClick={()=>{closeModal!()}}>
            {t('basico.cancelar')}
          </Button>

          <Button type='primary' onClick={()=>{finish()}}>
            {t('basico.aceptar')}
          </Button>
        </div>
        </>
        }

        {complete&&
        <FinishedSuccess titulo={message}  onFunction={closeModal} /> 
        }

        </Spiner>
    </div>
  )
}

export default RemoveSensor