import { Card, Pagination } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { getHistorical } from '../../Api/Subsidiary'
import { getHistorical as getHistoricalOrganization } from '../../Api/Organization'

import { useParams } from 'react-router-dom'
import { Historical } from '../../Models/Historical'
import SkeletonSensorSiteTable from '../../Components/SkeletonComponents/SkeletonSensorSiteTable'
import TableHistorical from '../../Components/TableHistoricalSensor/TableHistorical'

//transalate
import { useTranslation} from 'react-i18next';

import "./Historic.css"
import NameDateFilter from '../../Components/NameDateFilter/NameDateFilter'


const Historic = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [historic, setHistoric] = useState<Historical[]>([]);
  const [assetName, setAssetName] = useState<string>("");

  const [historicFilter, setHistoricFilter] = useState<Historical[]>([]);
  const [filters, setFilters] = useState<{nombre:string, dates:any[] }>({nombre:"", dates:[]}); //filters



  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 6
  
  //loading
  const [loading, setLoading] = useState(false);

  const { sendError, sesion } = useContext(PromiseSesionContext);


  const getHistoricData = async () => {
    try{
      setLoading(true)
      let res = sesion.context.rol==="Sucursal" ? await getHistorical(params.id?.toString() || "") : await getHistoricalOrganization(params.id?.toString() || "", params.idSucursal?.toString() || "")

      if(res.length>0) setAssetName(res[0].activo.nombre)



      setHistoric(res)
      setHistoricFilter(res)
      setLoading(false)

  }
  catch(err){

    setLoading(false)
    sendError({type: "ERROR", data:{message:t('basico.errorObtenerDatos')}})

  }

  }

  useEffect(() => {
    getHistoricData();
  }, []);



  const filterHistoric = ()=>{
    let newHistoric = historic

    if(filters.nombre!=""){
      newHistoric = historic.filter((x:Historical) => x.nombre.toUpperCase().includes(filters.nombre.toUpperCase()) || x.sensor_numero_de_serie.toUpperCase().includes(filters.nombre.toUpperCase())  )
    }

    if(filters.dates.length>0){
      newHistoric = historic.filter((x:Historical) => new Date(x.taskDetails.started) <= new Date(filters.dates[1]) && new Date(x.taskDetails.started) >= new Date(filters.dates[0]) )

    }


    setHistoricFilter(newHistoric)
  
  }

  useEffect(() => {
    if(historic.length>0){
      filterHistoric()
    }
  }, [filters]);


  return (
    <div>
        <Card  title={ assetName } style={{height:"calc(100vh - 2rem)" }} bodyStyle={{padding:0, height: "82%" }}  >
          <div className='Layout-Card-Paginate'>
          <NameDateFilter filters={filters} setFilters={setFilters} />

          <SkeletonSensorSiteTable loading={loading}> 
          <div className='historic-table'>
            <TableHistorical historical={historicFilter?.slice((page - 1) * sizePages, page * sizePages)} ></TableHistorical>
          </div>
          </SkeletonSensorSiteTable>
            <div className='centerPaginate'>
              {historicFilter.length > sizePages &&<Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={historic.length} pageSize={sizePages} showSizeChanger={false} />}
            </div>
          </div>
        </Card>
    </div>
  )
}

export default Historic