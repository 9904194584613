import React from 'react'

//styles
import './UserManageForm.css'

import { Button, Form, Input } from 'antd'

import Spiner from '../Spiner/Spiner'
import { useTranslation } from 'react-i18next'

export type FieldType = {
    nombres: string;
    apellidos: string;
    usuario:string
    contrasena:string;
    remember?: string;
  };

  interface Props {
    defaultValues?:FieldType
    onFinishMaster: (values: any) => void
    masterLoading:boolean
}
const UserManageForm = ({masterLoading, onFinishMaster, defaultValues}:Props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(false);

    const  onFinish = async (values: FieldType) => {
        onFinishMaster(values)
    };
      
      const onFinishFailed = (errorInfo: any) => {
      };
      

      
  return (
    <div className='UserManageForm-Component'>
        <div>
            <img src='/image/Zebra-Sensores.jpg'></img>
        </div>
        <div>
            <div className='UserManageForm-Form'>
                <img src='/image/logo.png'></img>
                <Spiner loading={masterLoading}>

                <span>{t('login.mensaje1')}:</span>
                    <Form
                    disabled={loading}
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 500 }}
                    
                    initialValues={defaultValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">


                    <Form.Item<FieldType>
                    label={t('basico.nombre')}
                    name="nombres"
                    rules={[{ required: true, message: t('basico.errorNombre') }]}
                    >
                    <Input placeholder={t('usuario.placeHolderNombre')} />
                    </Form.Item>

                    <Form.Item<FieldType>
                    label={t('basico.apellido')}
                    name="apellidos"
                    rules={[{ required: true, message: t('basico.errorApellido') }]}
                    >
                    <Input placeholder={t('usuario.placeHolderApellido')} />
                    </Form.Item>

                    <Form.Item<FieldType>
                    label={t('basico.usuario')}
                    name="usuario"
                    rules={[{ required: true, message: t('basico.errorUsuario') }]}
                    >
                    <Input placeholder={t('usuario.placeHolderUsuario')} />
                    </Form.Item>

                    <Form.Item<FieldType>
                    label={t('basico.clave')}
                    name="contrasena"
                    rules={[{ required: true, message: t('basico.errorClave') }]}
                    >
                    <Input.Password placeholder={t('usuario.placeHolderClave')} />
                    </Form.Item>

                    {/* <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                    >
                    <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        {t('basico.aceptar')}
                    </Button>
                    </Form.Item>
                </Form>
                {/* <ButtonPrimary text='Login'></ButtonPrimary> */}
        </Spiner>

            </div>
        </div>
    </div>
  )
}

export default UserManageForm


