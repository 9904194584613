import { Button, DatePicker, Form, Input, Select } from 'antd';
import { Reseller } from '../../Models/Reseller';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

//transalate
import { useTranslation} from 'react-i18next';

export type FieldType = {
    apikey?: string;
    reseller_id?: number;
    date?: Date

  };

interface Props{
    onFinishMaster: (values: any) => void
    defaultValues?:FieldType
    resellers : Reseller[]
  }

const AsignApiKeyForm = ({onFinishMaster, defaultValues, resellers}:Props) => {
  const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
        
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };


    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < dayjs().endOf('day');
    };

  return (

    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={defaultValues}
    >
    <div className='CreateClient-Form'>
   
        <Form.Item<FieldType>
            name={"reseller_id"}
            label={t('basico.revendedor')}
            rules={[
            {
            required: true,
            message: t('basico.seleccionRevendedor'),
            },
            ]} style={{width:"100%"}}>

            <Select style={{width:"100%"}} placeholder={t('basico.seleccionRevendedor')}>
                { resellers.map((reseller:Reseller)=>{return <Select.Option value={reseller.id}>{reseller.nombre}</Select.Option>}) }
            </Select>
        </Form.Item>

        <Form.Item<FieldType>
        label="ApiKey"
        name="apikey"
        style={{width:"49%"}}
        
        rules={[{ required: true, message: t('basico.errorApikey') }, {max:40, message:t('basico.max40')}]}
        >
        <Input placeholder={t('basico.placeHolderApikey')} />
        </Form.Item>
        
        <Form.Item<FieldType>  name={"date"}
        rules={[{ required: true, message: t('basico.errorFecha') }]} 
        label={t('basico.vencimiento')} 
        style={{width:"49%"}} >
          <DatePicker placeholder={t('basico.placeHolderFecha')}  style={{width:"100%"}} disabledDate={disabledDate} />
        </Form.Item>

    </div>

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>

    </Form>
  )
}

export default AsignApiKeyForm