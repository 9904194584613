import React from 'react'
import { MenuItem } from '../../Models/MenuItem'
import Menu from '../Menu/Menu'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CloudOutlined} from '@ant-design/icons'
import { AiOutlineQuestion } from "react-icons/ai";
import { generateTempFormat } from '../../Functions/temp'
interface Props{
    id?: string | number,
    title: string,
    status?: string,
    statusColor?:string,
    image: string,
    description?: string,
    dontShowStatus?: boolean
    menuItems?: MenuItem[]
    onClick?: (id: string | number) => void   | undefined | null,
    propsStyles?: React.CSSProperties,
  
    tempLow?:number
    tempMax?:number
    elements?:string
    sensors?:number
    haveTask?:boolean
    lastTemperature?:number
    showAlarms?:boolean,
    alarms?:number
    elementClass?: string 
  
  
  }

const NewElementCards = ({id, title, lastTemperature, haveTask, status, statusColor, image, description, dontShowStatus, menuItems, onClick, propsStyles, tempLow, tempMax, elements, sensors, showAlarms, alarms, elementClass}:Props) => {
    const { t } = useTranslation();
  
    const styles = {
        cursor: onClick ? "pointer" : "" 
        ,
        ...propsStyles
      }
  
    return (
    <div className={'NewElementCard ' + (elementClass && elementClass)} style={styles} onClick={(e)=>{e.stopPropagation(); if(onClick && id)onClick(id)}}>
       {(menuItems) && <Menu menuItems={menuItems}></Menu>}

       <div className='NewElementCardHeader'>
        <div className='NewElementCardImage'>
            <img src={image || `/image/reseller.png`} />
        </div>

        <div className='NewElementCardHeaderTitle'>
            <span className='ElementCard-title'>{title}</span>
            {
              description && <span className='ElementCard-description'>{(description).slice(0, 28)+ (description.length > 28 ? "..." : "")} </span>
            }

        </div>
       </div>

       <div className='NewElementCardFooter'>

        <div className='NewElementCardTag'>
            {/* <TagOutlined /> */}

            <span>
                {sensors}  {(sensors && sensors> 1 || sensors==0) ? t('basico.sensors'):t('basico.sensor')}
            </span>

            { <div className='NewElementCardTagStatus' style={{background:!haveTask? "#e9e9e9":(alarms && alarms> 0) ? "#FE0000" : "#00FF11", color: !haveTask ?"#000": "#fff", border:!haveTask ?"1px solid #000": ""}}>
                {!haveTask? <AiOutlineQuestion /> :(alarms && alarms> 0) ? <CloudOutlined></CloudOutlined> : <CheckOutlined></CheckOutlined>}
            </div>}

        </div>

        <div className='NewElementCardStatus'>
            <span>{ lastTemperature ? generateTempFormat(lastTemperature) : "--"}</span>
        </div>

       </div>

    </div>
  )
}

export default NewElementCards